.contactUsFooter {
  justify-content: end;
  height: 14rem;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}
.contactUsContainer {
  height: calc(50rem - 20px);
  // background: #0E0320;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .contactUsImg {
    position: absolute;
    top: 10%;
    left: 2%;
    z-index: -10;
    width: 90%;
    opacity: 25%;
  }
  .contactUsContent {
    display: inline-flex;
    padding: 0rem 5rem;
    align-items: flex-start;
    gap: 5.125rem;
    .contactUsInfoSection {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2.375rem;
      > p {
        color: #e3e2ff;
        font-family: Poppins;
        font-size: 3.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 4.5rem; /* 116.129% */
        margin: 0;
        margin-top: -16px; //added to remove top spacing which was coming from line height
      }
      > div.interactiveBtn {
        text-transform: none;
      }
    }
    .contactUsDetailsSection {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 2rem;
      .contactUsMail {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.875rem;
        > p:nth-child(1) {
          color: #cfcfcf;
          font-family: Poppins;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.875rem; /* 150% */
          letter-spacing: -0.00625rem;
          text-transform: uppercase;
          margin: 0;
        }
        > p:nth-child(2) {
          color: #fff;
          font-family: Poppins;
          font-size: 1.625rem;
          font-style: normal;
          font-weight: 500;
          line-height: 163.5%; /* 2.65688rem */
        }
      }
      .contactUsAddressSection {
        display: flex;
        align-items: flex-end;
        gap: 2rem;
        .contactUsaddress {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1.5rem;
          .contactUsaddressLocation {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.875rem;
            > p {
              color: #cfcfcf;
              font-family: Poppins;
              font-size: 1.25rem;
              font-style: normal;
              font-weight: 500;
              line-height: 1.875rem; /* 150% */
              letter-spacing: -0.00625rem;
              text-transform: uppercase;
              margin: 0;
            }
            > div {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              > span {
                color: #fff;
                font-family: Poppins;
                font-size: 1.625rem;
                font-style: normal;
                font-weight: 500;
                line-height: 163.5%; /* 2.65688rem */
                display: block;
              }
              .phdot {
                padding-bottom: 7%;
              }
            }
          }
          .contactUsaddressDetails {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.625rem;
            > div > p {
              color: #fff;
              font-family: Poppins;
              font-size: 1.625rem;
              font-style: normal;
              font-weight: 500;
              line-height: 163.5%; /* 2.65688rem */
              margin: 0;
            }
            .getDirections {
              .interactiveBtn {
                background: none;
                border-radius: 3.125rem;
                border: 1px solid #fff;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                width: 260px;
                align-items: center;
                > span {
                  font-size: 1.25rem;
                  margin-right: 5%;
                }
              }
              .interactiveBtn:hover {
                background: white !important;
                > span {
                  color: red;
                }
                > img {
                  content: url("../assets//redArrow.svg");
                }
              }
            }
          }
        }
      }
    }
  }
}

.contact-us-modal {
  .modal-content {
    background: #1f0f39;
    color: white;
    border-radius: 2%;
    padding: 2%;
    border: 1px solid rgba(253, 149, 255, 0.3);
  }
  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
    .modal-title {
      color: #e3e2ff;
      font-family: Poppins;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 500;
      line-height: 2rem; /* 114.286% */
    }
    > button {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Cpath d='M24.4003 7.61339C24.2769 7.48978 24.1304 7.39172 23.9691 7.32481C23.8078 7.2579 23.6349 7.22346 23.4603 7.22346C23.2857 7.22346 23.1128 7.2579 22.9515 7.32481C22.7902 7.39172 22.6436 7.48978 22.5203 7.61339L16.0003 14.1201L9.4803 7.60005C9.35686 7.47661 9.21031 7.37869 9.04902 7.31188C8.88774 7.24508 8.71487 7.21069 8.5403 7.21069C8.36572 7.21069 8.19286 7.24508 8.03157 7.31188C7.87029 7.37869 7.72374 7.47661 7.6003 7.60005C7.47686 7.7235 7.37894 7.87004 7.31213 8.03133C7.24532 8.19261 7.21094 8.36548 7.21094 8.54005C7.21094 8.71463 7.24532 8.88749 7.31213 9.04878C7.37894 9.21006 7.47686 9.35661 7.6003 9.48005L14.1203 16.0001L7.6003 22.5201C7.47686 22.6435 7.37894 22.79 7.31213 22.9513C7.24532 23.1126 7.21094 23.2855 7.21094 23.4601C7.21094 23.6346 7.24532 23.8075 7.31213 23.9688C7.37894 24.1301 7.47686 24.2766 7.6003 24.4001C7.72374 24.5235 7.87029 24.6214 8.03157 24.6882C8.19286 24.755 8.36572 24.7894 8.5403 24.7894C8.71487 24.7894 8.88774 24.755 9.04902 24.6882C9.21031 24.6214 9.35686 24.5235 9.4803 24.4001L16.0003 17.8801L22.5203 24.4001C22.6437 24.5235 22.7903 24.6214 22.9516 24.6882C23.1129 24.755 23.2857 24.7894 23.4603 24.7894C23.6349 24.7894 23.8077 24.755 23.969 24.6882C24.1303 24.6214 24.2769 24.5235 24.4003 24.4001C24.5237 24.2766 24.6217 24.1301 24.6885 23.9688C24.7553 23.8075 24.7897 23.6346 24.7897 23.4601C24.7897 23.2855 24.7553 23.1126 24.6885 22.9513C24.6217 22.79 24.5237 22.6435 24.4003 22.5201L17.8803 16.0001L24.4003 9.48005C24.907 8.97339 24.907 8.12005 24.4003 7.61339Z' fill='%23E3E2FF'/%3E%3C/svg%3E");
      background-repeat: no-repeat no-repeat;
      background-position: center center;
      background-size: cover;
      opacity: 100%;
    }
  }
  .modal-footer {
    border-top: none;
    padding-top: 0;
    > .interactiveBtn {
      display: flex;
      padding: 0.6rem 2rem;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: auto;
      height: auto;
      > span {
        color: #fff;
        text-align: center;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 500;
        line-height: 132%; /* 1.65rem */
      }
    }
  }
  .jobFormInputs {
    height: 3rem;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.16);
    opacity: 0.6;
    background: #0e0320;
    color: #ffffff;
    text-indent: 16px;
    padding: 0;
    width: 100%;
    font-size: 1.3rem;
    .PhoneInputInput {
      background: inherit;
      border: none;
      color: #fff;
      text-indent: 8px;
    }
    .PhoneInputCountry {
      padding-left: 16px;
    }
  }
  .jobFormInputs::placeholder,
  .PhoneInputInput::placeholder {
    color: rgba(255, 255, 255, 0.6);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    // background: #0E0320;
    line-height: 3rem; /* 1.83938rem */
  }

  .form-flex {
    display: flex;
    gap: 1rem;
  }

  .form-check-label {
    color: #e3e2ff;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 132%; /* 1.32rem */
    padding-left: 8px;
  }

  .contact-us-checkbox > input {
    border-radius: 0;
    background-color: #1f0f39;
  }
  .contact-us-checkbox > input::checked {
    border-radius: 0;
    background-color: #1f0f39;
    border-color: #1f0f39;
  }

  .contactformError {
    color: #ff6363;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem; /* 114.286% */
  }

  .privacystatement {
    color: #e3e2ff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 132%;
    text-decoration-line: underline;
  }

  .popUpContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin: 10%;
    .popUpMessage {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      > p {
        margin: 0;
      }
      > p:nth-child(1) {
        color: #e3e2ff;
        font-family: Poppins;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2rem; /* 114.286% */
      }
      > p:nth-child(2) {
        color: #e3e2ff;
        font-family: Poppins;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2rem; /* 114.286% */
      }
    }
    .popUpClose {
      > button {
        display: flex;
        padding: 0.75rem 1.5rem;
        justify-content: center;
        align-items: center;
        border-radius: 2.5rem;
        background: #665bd8;
        width: 9.25rem;
        height: 3rem;
        gap: 0.25rem;
        color: white;
        border: none;
        font-family: poppins;
        > img {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
}
// -------------------------------------------mediaQurrey----------------------------------------------------------------
@media (min-width: 993px) and (max-width: 1366px) {
  .contactUsContainer .contactUsContent {
    padding: 0 30px;
    gap: 3rem;
  }
  .contactUsContainer .contactUsContent .contactUsInfoSection > p {
    font-size: 48px;
    width: 21rem;
    line-height: 3.5rem;
  }
  .contactUsContainer
    .contactUsContent
    .contactUsDetailsSection
    .contactUsAddressSection
    .contactUsaddress
    .contactUsaddressDetails
    > div
    > p {
    font-size: 1.5rem;
  }
  .contactUsContainer
    .contactUsContent
    .contactUsDetailsSection
    .contactUsAddressSection
    .contactUsaddress {
    gap: 0;
  }
}

@media (max-width: 992px) {
  .contactUsContainer {
    display: block;
    margin-top: 10%;
    height: 100%;
    .contactUsImg {
      left: 12%;
      top: -19%;
      > img {
        width: 100%;
        height: 35rem;
        opacity: 30%;
      }
    }
    .contactUsContent {
      display: flex;
      padding: 2% 5%;
      flex-direction: column;
      gap: 2rem;
      align-items: center;
      .contactUsInfoSection {
        gap: 1rem;
        > p {
          font-size: 2rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2.5rem; /* 125% */
          letter-spacing: -0.04rem;
          text-align: center;
        }
        align-items: center;
      }
      .contactUsDetailsSection {
        margin-top: 15%;
        align-items: center;
        .contactUsMail {
          align-items: center;
          gap: 0;
          > p:nth-child(1) {
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.875rem; /* 187.5% */
            letter-spacing: -0.005rem;
            text-transform: uppercase;
          }
          > p:nth-child(2) {
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 500;
            line-height: 163.5%; /* 2.04375rem */
          }
        }
        .contactUsAddressSection {
          align-items: center;
          flex-direction: column;
          .contactUsaddress {
            align-items: center;
            margin-top: 0%;
            .contactUsaddressLocation {
              align-items: center;
              > div {
                text-align: center;
                > p {
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 1.875rem; /* 187.5% */
                  letter-spacing: -0.005rem;
                  text-transform: uppercase;
                }
                > div > span {
                  font-size: 1.25rem;
                }
                .phdot {
                  margin: 0 5px;
                  padding-bottom: 6%;
                }
              }
            }
            .contactUsaddressDetails {
              align-items: center;
              > div:nth-child(1) > p {
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: 163.5%; /* 2.04375rem */
                text-align: center;
              }
              .getDirections {
                > a > .interactiveBtn {
                  gap: 0.1rem;
                  align-items: center;
                  padding: 10px;
                  > span {
                    font-size: 0.9rem;
                  }
                  > img {
                    width: 1.2rem;
                    height: 1.2rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .contact-us-modal {
    top: -6%;
    .modal-title {
      font-size: 1.25rem;
    }
    .jobFormInputs {
      text-indent: 5%;
    }
    .PhoneInputCountry {
      padding-left: 5% !important;
    }
    .form-flex {
      display: flex;
      gap: 1rem;
      flex-direction: column;
    }

    .form-check-label {
      color: #e3e2ff;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 132%; /* 1.32rem */
      margin-left: 2%;
    }

    .contact-us-checkbox > input {
      opacity: 100%;
      border: 2px solid white;
    }

    .popUpContainer > .popUpMessage {
      > p:nth-child(1) {
        text-align: center;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.5rem;
      }
      > p:nth-child(2) {
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 132%; /* 1.32rem */
      }
    }
  }
}
