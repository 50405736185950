.video-container {
  position: relative;
  width: 95%;
  height: 100%;
}

.video {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
  object-fit: cover;
  border-radius: 2%;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
  color: white;
  cursor: pointer;
  display: none;
}

.play-button > img:hover {
  content: url("../assets/career/playerIconRed.svg");
}

// -------------------------------------------mediaQuery----------------------------------------------------------------
@media (min-width: 993px) and (max-width: 1366px) {
  .video-container {
    width: 100%;
  }
}
@media (max-width: 992px) {
  .play-button > img {
    display: none;
    width: 3rem;
    height: 3rem;
  }
}
