.capabilitiesCard {
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 627px;
  height: 280px;
  border-radius: 24px;
  background: linear-gradient(
    185deg,
    rgba(142, 97, 189, 0.2) -26.06%,
    rgba(97, 73, 164, 0.2) 121.46%
  );

  filter: blur(0px);
}

.capabilitiesCardTitle {
  color: #fff;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 107.143% */
  letter-spacing: -0.56px;
  margin-bottom: 12px;
}

.capabilitiesCardText {
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px; /* 140% */
  margin-bottom: 32px;
}

.capabilitiesCardBtn {
}

@media (max-width: 1366px) {
  .capabilitiesCard {
    height: 208px;
    padding: 16px;
  }

  .capabilitiesCardTitle {
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
    letter-spacing: -0.4px;
    margin-bottom: 8px;
  }

  .capabilitiesCardText {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    margin-bottom: 20px;
    width: 100%;
    height: 60%;
  }
}
////------------------------------1221-1366------------------------------------------------------------------------------------
@media (min-width: 993px) and (max-width: 1366px) {
  .capabilitiesCard {
    width: 48.5%;
    padding: 24px;
  }
}

@media (max-width: 992px) {
  .capabilitiesCard {
    width: 343px;
  }
}
