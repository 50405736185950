.ourSolutionsContainer {
  display: flex;
  flex-direction: column;
  background: #0e0320;
  align-items: center;
}

.ourSolutionsText {
  color: #ff988a;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140.5%; /* 33.72px */
  letter-spacing: -0.48px;
  margin-bottom: 12px;
}

.empoweringText {
  color: #eff2ef;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px; /* 128.571% */
  letter-spacing: -0.84px;
  margin-bottom: 80px;
}
.ourSolutionsCard {
  display: flex;
  justify-content: center;
  gap: 109px;
  padding-left: 112px;
  padding-right: 112px;
  margin-bottom: 120px;
  width: 100%;
}
.reverseSolutionsLayout {
  flex-direction: row-reverse;
}

.ourSolutionsCardImg {
  width: 628px;
  height: 520px;
}
.ourSolutionCardDetails {
  display: flex;
  flex-direction: column;
  height: 527px;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 480px;
}

.headingCardSolutions {
  margin-bottom: 21px;
  color: #fff;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 35.573px; /* 111.165% */
}

.ourSolutionsDetail {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 466px;
  margin-bottom: 40px;
}
.offeringText {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 166.667% */
  letter-spacing: -0.09px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.ourSolutionsCardLinks {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 32px;
}
.ourSolutionsCardChildLinks {
  display: flex;
  color: #eedeff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  letter-spacing: -0.32px;
  gap: 12px;
  flex-wrap: wrap;
}

// ===========================================================media============================================
@media (min-width: 993px) and (max-width: 1366px) {
  .ourSolutionsCardImg {
    width: 450px;
    height: 445px;
    object-fit: cover;
    border-radius: 25px;
  }
  .ourSolutionsCard {
    gap: 30px;
  }
  .ourSolutionCardDetails {
    height: auto;
  }
}
@media (max-width: 992px) {
  .ourSolutionsCard {
    flex-direction: column;
    padding: 1rem;
    gap: 0;
    margin-bottom: 24px;
    width: 343px;
  }

  .reverseFlex {
    flex-direction: column-reverse;
  }

  .ourSolutionsCardImg {
    width: 100%;
    height: 284.013px;
    margin-bottom: 16px;
  }
  .ourSolutionsText {
    color: #ff988a;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.5%; /* 22.48px */
    letter-spacing: -0.32px;
    margin-bottom: 8px;
  }
  .empoweringText {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
    margin-bottom: 24px;
  }

  .headingCardSolutions {
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 35.573px; /* 148.221% */
    margin-bottom: 8px;
  }

  .ourSolutionsDetail {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 100%;
    margin-bottom: 24px;
  }
  .offeringText {
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
    letter-spacing: -0.06px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .ourSolutionsCardChildLinks {
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
    letter-spacing: -0.06px;
    text-transform: uppercase;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .ourSolutionsCardLinks {
    margin-bottom: 24px;
  }

  .ourSolutionCardDetails {
    align-items: center;
    height: auto;
    width: 100%;
  }
}
