.interactiveBtn {
  background-color: rgba(102, 91, 216, 1);
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 132%;
  color: rgba(255, 254, 252, 1);
  padding: 20px;
  width: 251px;
  height: 72px;
  border-radius: 40px;
  transition: padding-left 0.3s ease;
  cursor: pointer;
}

.blueFill {
  background-color: rgba(102, 91, 216, 1) !important;
}
.noFill {
  background-color: transparent;
  color: rgba(255, 254, 252, 1);
  border: 1px solid white;
}

.interactiveBtn:hover {
  background-color: rgba(250, 86, 34, 1);
}

.noFill:hover {
  background-color: transparent;
  img {
    fill: white;
  }
}

.interactiveBtn:hover .arrowInteractiveTransform {
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

@media (max-width: 1385px) {
  .interactiveBtn {
    color: #fffefc;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    width: fit-content;
    height: 44px;
    padding: 0px 20px;
    line-height: 44px;
  }
  .arrowInteractiveTransform {
    width: 1.75rem;
  }
}
// Initiate now
