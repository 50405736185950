.privacyContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 120px;
  padding-top: 80px;
  gap: 80px;
}

.privacyContainer ul {
  padding-left: 20px !important;
}

.privacyHeading {
  display: flex;
  width: 846px;
  align-items: center;
  justify-content: center;
  color: #e3e2ff;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 171.429% */
}

.privacyContent {
  width: 846px;

  color: #e3e2ff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1385px) {
  .privacyContainer {
    gap: 32px;
    width: 100%;
    padding-top: 32px;
    padding-bottom: 72px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .privacyHeading {
    color: #e3e2ff;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 116.667% */
    letter-spacing: -0.48px;
    width: 343px;
  }

  .privacyContent {
    width: 100%;
    color: #e3e2ff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 200% */
  }
}
