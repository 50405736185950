.titleContainer {
  width: 100%;
  height: 692px;
  display: flex;
  justify-content: center;
}

.titleParentContianer {
  width: 100%;
  height: 692px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  // background-image: url("../assets/company_banner_bg.svg");
}
.bGSecion {
  // width: 100%;
  // height: 100%;
  position: absolute;
  background: url("../assets/company_banner_bg.svg") no-repeat center center;
  background-size: cover;
  top: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-size: cover;
}

.titleContent {
  position: relative;
  height: 60%;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleContentLeft {
  font-size: 62px;
  white-space: pre-wrap;
  font-weight: 600;
  color: #e3e2ff;
  max-width: 50%;
}

// .linkedIconClass {
//   img {
//     transform: scale(1.1);
//   }
// }

@keyframes vertical {
  0% {
    opacity: 0;
  }
  4% {
    opacity: 0;
    -webkit-transform: translateY(-360px);
    transform: translateY(45px);
  }
  10% {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateY(0px);
  }
  35% {
    opacity: 0;
    -webkit-transform: translateX(0px);
    transform: translateY(0px);
  }
  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.verticalFlipContainer {
  display: flex;
  flex-direction: row;
  position: relative;
}

.verticalflip {
  // font-size: 300%;
  // font-weight: bold;
  padding-left: 20px;
  position: relative;
}

.verticalflip span {
  animation: vertical 5.5s linear infinite 0s;
  -ms-animation: vertical 5.5s linear infinite 0s;
  -webkit-animation: vertical 5.5s linear infinite 0s;

  position: absolute;
  opacity: 0;
  background: -webkit-linear-gradient(97deg, #fd95ff 2.52%, #9747ff 104.52%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  // line-height: 72px;
}

.verticalflip span:nth-child(1) {
  -ms-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.verticalflip span:nth-child(2) {
  -ms-animation-delay: 7.5s;
  -webkit-animation-delay: 7.5s;
  animation-delay: 7.5s;
}

.verticalflip span:nth-child(3) {
  -ms-animation-delay: 9.5s;
  -webkit-animation-delay: 9.5s;
  animation-delay: 9.5s;
}

.titleContenRight {
  font-size: 1.75em;
  font-weight: 300;
  line-height: normal;
  color: #efedfd;
  max-width: 25%;
  margin-top: 150px;
  max-width: 405px;
  line-break: auto;
}

.videoWrapper {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 0;
  margin-top: -250px;
  border-radius: 50px;
}

.videoCompany {
  // width: inherit !important;
  height: 621px !important;
  background-size: cover;
  transition: 1s opacity;
  object-fit: cover;
  width: 80%;
  border-radius: 50px;
}

.videoContainer {
  width: "100%";
  padding: 0 25%;
}

.playButtonContainer {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 13px;
  // cursor: pointer;
  pointer-events: auto;
  z-index: 1;
  img {
    display: none;
  }
}

.videoComponent {
  width: 100%;
  background: #0c0225;
  border-radius: 30px;
}

.valueContainer {
  width: 100%;
  max-height: 30%;
  padding: 120px 50px;
}

.valueContainerTitle {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.valueContainerTitleHead {
  color: #ff988a;
  font-weight: 500;
  font-size: 24px;
}

.valueContainerTitleDes {
  color: #fff;
  font-size: 36px;
  font-weight: 300;
  text-align: center;
  max-width: 800px;
  font-weight: 600;
}

.valueContainerTitleDesRes {
  display: none;
  visibility: hidden;
}

.impactParentContainer {
  padding: 30px;
  margin: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ourStoryContainer {
  display: flex;
  width: 100%;
  max-height: 841px;
  padding: 120px;
  padding-top: 0px;
}

.ourStoryLeftContainer {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 50%;
}

.ourStoryDes {
  color: #fff;
  font-size: 24px;
  line-height: 25px;
  margin: 30px 0;
}

.ourStoryContainerTitleDesRes {
  color: #fff;
  font-size: 36px;
  font-weight: 300;
  // text-align: center;
  max-width: 800px;
  font-weight: 600;
}

.ourStoryRightContainer {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 50%;

  img {
    max-height: 100%;
  }
}

.ourStoryContainerTitleHead {
  color: #ff988a;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 15px;
}

.titleHeadContainer {
  color: #ff988a;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 15px;
}
.titleHeadContainer {
  color: #ff988a;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 15px;
}

.titleHeadDescription {
  color: #fff;
  font-size: 36px;
  font-weight: 300;
  // text-align: center;
  max-width: 800px;
  font-weight: 600;
}

.ourTeamContainer {
  display: flex;
  flex-direction: column;
  padding: 0 120px 120px 120px;
  position: -webkit-sticky;
  position: sticky;
}

.ourTeamContainerSticky {
  position: sticky;
  top: 0;
}

.ourTeamInfoContainer {
  display: flex;
  flex-direction: row;
  margin-top: 60px;
}

.ourTeamContainerLeft {
  flex-direction: row;
  width: 30%;
  margin-top: 10px;
  // overflow-y: auto;
  height: 600px;
}

.ourTeamContainerRight {
  flex-direction: row;
  width: 70%;
  height: 700px;
  overflow-y: auto;
  // overflow: hidden;
}

.ourTeamContainerRight::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.ourTeamContainerLeftOption {
  height: 86px;
  width: 261px;
  color: #fff;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  padding: 10px 32px 10px 32px;
  cursor: pointer;
  will-change: transform; /* Notify the browser to optimize for transform changes */
  transition: transform 0.2s ease-out;
}

.ourTeamItemEnabled {
  background: url("../assets/CompanyPage/teamcategoryIcon.svg") no-repeat center
    center;
}

.ourTeamItemDisabled {
  background: url("../assets/CompanyPage/teamcategoryIconDisabled.svg")
    no-repeat center center;
}

.ourTeamPeopleContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.csrSection {
  display: flex;
  justify-content: center;
  width: 100%;
}

.csrLeft {
  color: #efedfd;
  font-family: Poppins;
  p {
    max-width: 70%;
    font-size: 24px;
    margin-top: 10px;
  }
}
.leftDes {
  max-width: 60%;
}
.leftBtnDiv {
  margin-top: 40px;
  text-decoration: none;
}

.csrRight {
  display: flex;
  align-items: center;
  justify-content: center;
}

.csrLeftContent {
  width: 480px;
}

.csrSectionContainer {
  display: flex;
  width: 100%;
  gap: 109px;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
}

.founderContainer {
  width: 100%;
  background: url("../assets/homepage/testimonial_single_BG.png") no-repeat
    center center;
  background-size: cover;
  min-height: 629px;
  height: 100%;
  display: flex;
  margin: 0 0 120px 0;
}

.founderContainerLeft {
  width: 50%;
  padding: 50px;
  color: #fff;
}
.founderContainerLeftImg {
  padding: 0 120px;
}

.founderContainerLeftContent {
  padding: 50px 120px;
}
.founderContainerLeftContentDes {
  font-size: 36px;
}

.founderContainerLeftDesignation {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
}

.founderContainerLeftDesignationTitle {
  font-size: 1.75rem;
}

.founderContainerDesgnationInfo {
  font-size: 1.125rem;
}

.founderContainerRight {
  display: flex;
  // align-items: center;
  justify-content: center;
  width: 50%;
}

//*************************** Media queires *****************************
@media (min-width: 993px) and (max-width: 1366px) {
  .ourTeamContainer {
    padding: 0 30px 120px 30px;
  }
  .ourStoryDes {
    line-height: 18px;
  }
  .founderContainer {
    min-height: 520px;
  }
  .founderContainerLeft {
    padding: 30px;
  }
  .founderContainerLeftContent {
    padding: 10px 0;
  }
  .founderContainerLeftImg {
    padding: 0;
  }
  .founderContainerRight {
    align-items: flex-end;
    img {
      width: 400px;
    }
  }
  .csrSectionContainer {
    gap: 30px;
    padding: 0 30px 40px;
  }
  .csrLeftContent {
    width: auto;
  }
  .csrRight img {
    width: 470px;
  }
  .titleContentLeft {
    font-size: 48px;
    min-width: 450px;
  }
  .titleContenRight {
    margin-top: 219px;
  }
  .verticalflip {
    padding-left: 0;
  }
  .videoCompany {
    width: 100%;
    padding: 0 30px;
    height: 480px !important;
    border-radius: 4%;
  }
  .ourStoryContainer {
    padding: 0 30px 80px;
  }
  .ourStoryLeftContainer {
    padding: 0;
  }
  .ourTeamContainerRight {
    width: auto;
  }
  .ourTeamInfoContainer {
    gap: 30px;
  }
  .founderContainerLeftContentDes {
    font-size: 32px;
  }
  .valueContainer {
    padding: 80px 30px;
  }
  .impactParentContainer {
    padding: 0;
    margin: 40px 0 0;
    gap: 30px;
  }
}

@media (max-width: 992px) {
  .titleContent {
    justify-content: center;
    flex-direction: column;
  }

  .csrSectionContainer {
    flex-direction: column;
    gap: 24px;
    padding-bottom: 0px;
  }

  .founderContainer {
    margin-bottom: 48px;
  }

  .titleHeadContainer {
    font-size: 16px;
  }

  .titleContentLeft {
    font-size: 32px;
    max-width: 100%;
    text-align: center;
  }

  .titleContenRight {
    max-width: 100%;
    margin-top: 60px;
    width: 100%;
    font-size: 16px;
    text-align: center;
  }

  .impactParentContainer {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }

  .valueContainerTitleDes {
    display: none;
    visibility: hidden;
  }

  .valueContainerTitleDesRes {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    display: block;
    visibility: visible;
  }

  .videoWrapper {
    margin-top: -340px;
    // height: 0;
  }

  .videoCompany {
    // width: inherit !important;
    height: 200px !important;
    width: 90%;
    border-radius: 8px;
  }

  .playButtonContainer {
    width: 90%;
  }

  .titleHeadContainer {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .titleHeadDescription {
    text-align: center;
    font-size: 20px;
    margin-bottom: 24px;
  }

  .valueContainer {
    padding-top: 50px;
    padding-bottom: 0px;
  }

  .ourStoryContainer {
    padding: 0;
    flex-direction: column;
  }

  .ourStoryLeftContainer {
    width: 100%;
  }

  .ourStoryContainerTitleDesRes {
    font-size: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .ourStoryRightContainer {
    width: 100%;
  }

  .ourStoryDes {
    color: #fff;
    font-size: 24px;
    line-height: 15px;
    margin: 30px 0 30px 0px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .ourTeamContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .founderContainer {
    flex-direction: column;
  }

  .founderContainerLeft {
    width: 100%;
    padding-top: 0px;
  }
  .founderContainerLeftImg {
    padding: 0 0 40px 0;
  }
  .founderContainerLeftContent {
    padding: 0;
  }
  .founderContainerRight {
    width: 100%;
    img {
      max-width: 200px;
    }
  }

  .csrSection {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }
  .csrLeft {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .csrLeftContent {
    max-width: 100%;
    text-align: center;
  }
  .leftDes {
    max-width: 100%;
  }

  .leftBtnDiv {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .csrRight {
    width: 100%;
    img {
      width: 100%;
      height: 284px;
    }
  }

  .ourTeamContainerLeft {
    width: 50%;
  }

  .ourTeamContainerRight {
    width: 50%;
  }

  .ourTeamContainerLeftOption {
    width: 164px;
    height: 53.903px;
    flex-shrink: 0;
    font-size: 14px;
    background-size: cover;
    padding: 8px 30px 10px 23px;
  }

  .ourTeamPeopleContainer {
    flex-direction: column;
    margin-bottom: 50px;
  }

  .verticalFlipContainer {
    display: flex;
    flex-direction: column;
  }

  .verticalflip span {
    font-size: 2rem;
    top: 50%;
    left: 15%;
    transform: translate(-50%, -50%);
    /* Ensure the element has dimensions */
    width: 200px; /* or any value */
    height: 100px; /* or any value */
  }
}
