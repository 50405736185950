.pageNotFoundContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5%;
    color: white;
    font-family: popins;
    font-size: xx-large;
    gap: 1rem;
}

.pageNotFound{
    color: #E3E2FF;
text-align: center;
font-family: Poppins;
font-size: 42px;
font-style: normal;
font-weight: 700;
line-height: 52px; /* 123.81% */
letter-spacing: -0.84px;
}

.weAreSorry{
    color: #EFEDFD;
text-align: center;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

@media (max-width: 1385px) {
    .pageNotFoundContainer{
        margin-top: 5%;
        font-size: medium;
        padding: 1rem;

    }
    .pageNotFound{
        font-size: medium;
    }

    .weAreSorry{
        font-size: small;
    }
}