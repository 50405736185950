.heroContainerSolutions {
  background-image: url("../assets/solutionsLandingPage/heroBannerSolutionBackground.png");
  background-position: bottom center;
  top: -106px; /* Set top to 0 to make it flow from the top */
  height: 786px;
  width: 100%;
  display: inline-flex;
  padding: 326px 182px 267px 183px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: absolute;
}

.heroContainerSolutionsParent {
  width: 100%;
  height: 680px;
  position: relative;
}

.bannerSolutionContainer {
  position: absolute;
  align-items: center;
  top: 326px;
  display: flex;
  flex-direction: column;
  gap: 37px;
}

.dataDrivenText {
  color: #e3e2ff;
  text-align: center;
  font-family: Poppins;
  font-size: 62px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 116.129% */
}

.withNgenuxText {
  color: #efedfd;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 876px;
}

.poweredbyaiText {
  background: linear-gradient(97deg, #fd95ff 2.52%, #9747ff 104.52%);
  -webkit-background-clip: text;
  color: transparent;
}

.whatDefinesOurWorkContainer {
  background: #0e0320;
  display: flex;
  flex-direction: column;
  gap: 42px;
  align-items: center;
  padding: 0px 80px 120px 80px;
}
.definesText {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px; /* 123.81% */
  letter-spacing: -0.84px;
}

.cardWorkContainer {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.workCard {
  display: flex;
  padding-left: 38px;
  padding-right: 38px;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  opacity: 1;
  background: linear-gradient(
    185deg,
    rgba(142, 97, 189, 0.2) -26.06%,
    rgba(97, 73, 164, 0.2) 121.46%
  );

  filter: blur(0px);
  width: 411px;
  height: 333px;
}
.cardImgWork {
  margin-top: 49.98px;
}
.workCardTitle {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 107.143% */
  letter-spacing: -0.56px;
  margin-top: 42px;
}
.workCardFooter {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px; /* 140% */
  max-width: 411px;
  margin-top: 24px;
}
.methodologyContainer {
  background: #0e0320;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 80px 120px 81px;
}

.methodologyImgMobile {
  display: none;
}
.methodsLineDiagram {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  gap: 10px;
}

.methodBulletImg {
  width: 20.973px;
}
.methodCircleText {
  line-height: normal;
}
.methodCircleTextWidth {
  width: 175.4px;
  position: absolute;
  display: flex;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  left: 17px;
}

.circleDiv {
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
}

.line1 {
  position: absolute;
}

.methodologyImg {
  width: 1280px;
}

//==================================================================Media=========================================================
@media (min-width: 993px) and (max-width: 1366px) {
  .methodologyImg {
    width: 933px;
  }
  .workCard {
    width: 295px;
  }
  .dataDrivenText {
    font-size: 55px;
  }
  .cardImgWork {
    width: 70px;
    height: 62px;
    margin-top: 42px;
  }
}

@media (max-width: 992px) {
  .heroContainerSolutions {
    background-image: url("../assets/solutionsLandingPage/heroBannerSolutionsMobile.png");
    background-position: center center;
    background-size: cover;

    background-repeat: no-repeat;
    height: 390px;
    padding: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .heroContainerSolutionsParent {
    height: 282px;
  }

  .dataDrivenText {
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    letter-spacing: -0.64px;
    width: 100%;
  }

  .withNgenuxText {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    letter-spacing: -0.32px;
    width: 100%;
  }

  .bannerSolutionContainer {
    position: absolute;
    top: 150px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding-left: 18px;
    padding-right: 18px;
  }

  .whatDefinesOurWorkContainer {
    background: url("../assets/solutionsLandingPage/whatDefinesOurWorkBG.png");
    background-position: top center;
    background-size: cover;
    padding: 0px 16px 80px 16px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .definesText {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.5%; /* 28.1px */
    letter-spacing: -0.4px;
  }

  .cardWorkContainer {
    flex-direction: column;
  }

  .workCard {
    width: 343px;
    height: 236px;
    flex-shrink: 0;
    padding: 0px;
  }

  .cardImgWork {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    margin-top: 40px;
  }
  .workCardTitle {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
    letter-spacing: -0.4px;
    margin-top: 24px;
  }

  .workCardFooter {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
    margin-top: 12px;
  }
  .methodologyImg {
    display: none;
  }
  .methodologyImgMobile {
    display: block;
    width: 335px;
    height: 944px;
    padding-bottom: 52px;
  }

  .helperclassActivateText {
    width: 343px;
    margin-bottom: 32px;
  }
  .methodologyContainer {
    padding-bottom: 52px;
  }
}
