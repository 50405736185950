.insightsLanding-BG {
  .insightsLandingImg {
    background-repeat: no-repeat;
    width: 100%;
    position: absolute;
    height: 67rem;
    z-index: -10;
    top: 0;
  }
  .insightsLandingBGBox {
    width: 100%;
    height: 5rem;
    background: linear-gradient(180deg, #05020d 0%, #0e0320 100%);
  }
}
.insightsLanding {
  & .banner {
    height: 20rem;
    display: flex;
    justify-content: center;
    // background: #0c0225;
    margin: 0;
    align-items: center;
    flex-direction: column;
  }
  & .title {
    color: #e3e2ff;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 4rem;
    & span {
      color: red;
      // background: linear-gradient(97deg, #FD95FF 2.52%, #A04EFE 104.52%);
      background: #a04efe;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 3rem;
      font-style: normal;
      font-weight: 700;
      line-height: 4rem;
    }
  }
  & .tagline {
    color: #eff2ef;
    text-align: center;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.fedInsightsTitle {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%; /* 3.68813rem */
  letter-spacing: -0.0525rem;
}

.fedInsightsContainer {
  display: flex;
  gap: 20px;
  margin: 1.5rem 3.5rem;
  justify-content: center;
}

.fedInsightsCard {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 525px;
  width: 40%;
}

.fedInsightsImgBox {
  width: 100%;
  // height: 302px;
  // height: 100%;
  overflow: hidden;
  border-radius: 1.25rem;

  position: relative;
  img {
    border-radius: 1.25rem;
    width: 100%;
    height: auto;
  }
}

.fedInsightsTextCard {
  color: #eff2ef;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  // width: 373px;
  margin-top: 32px;
}

.fedInsightsTextCard p:first-child {
  font-size: 14px;
  margin-bottom: 10px;
}

.fedInsightsBtnCard {
  cursor: pointer;
  display: flex;
  width: 86px;
  height: 24px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: rgba(151, 129, 238, 1);
  text-align: left;
}
.fedInsightsBtnCard:hover {
  > img {
    transform: rotate(45deg);
    transition: transform 0.5s ease;
  }
}

.fedInsightsPillCard {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #8f4d7c;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  border-radius: 12px;
  opacity: 0.94;
  background: #fff;
  padding: 4px 12.813px 5.391px 13px;
}

.fedInsightsSubCard {
  display: flex;
  flex-direction: row;
  gap: 20px;
  cursor: pointer;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
}

.fedInsightssubImgBox {
  width: 19rem;
  height: 15.8rem;
  overflow: hidden;
  border-radius: 1.25rem;
  position: relative;
  img {
    border-radius: 1.25rem;
    width: 19rem;
    height: 15.8rem;
    object-fit: cover;
  }
}

.fedsubInsightsTextCardContainer {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.fedsubInsightsTextCard {
  color: #eff2ef;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 373px;
}

.fedsubcards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.fedsubInsightsTextCard p {
  margin: 0;
  color: #eff2ef;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fedsubInsightsTextCard p:first-child {
  margin-bottom: 10px;
  font-size: 14px;
}

.insightsCatContainer {
  background: linear-gradient(
    180deg,
    rgba(96, 50, 117, 0) 0%,
    rgba(96, 50, 117, 0.31) 28.9%,
    rgba(178, 61, 35, 0) 62.19%
  );
}
.insightsCatContainer {
  padding: 0 0 6rem 0;
}

.insightsCatTitle {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.0525rem;
}

.insightsCatTitle > p {
  margin: 0;
}

.insightsCatTitle > p:nth-child(2) {
  margin: 0;
  color: #fff;
  font-family: Poppins;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.0525rem;
}

.insightsCatFilter {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.75rem;
  margin: 3rem 0;
  button {
    text-align: center;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 132%;
    text-transform: capitalize;
    color: #fff;
    padding-top: 20px;
    border-radius: 40px;
    transition: padding-left 0.3s ease;
    cursor: pointer;
    padding: 0.375rem 1.375rem;
    border: 1px solid #3c2a74;
    background: rgba(102, 91, 216, 0.2);
  }
  button.selected {
    background-color: rgba(102, 91, 216, 1);
  }
  button:hover,
  button.selected:hover {
    background-color: rgb(250, 86, 34);
  }
}

.insightsLoadMore {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3.25rem;
  span {
    background-color: rgb(102, 91, 216);
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%;
    text-transform: capitalize;
    color: rgb(255, 254, 252);
    padding-top: 20px;
    width: 251px;
    height: 72px;
    border-radius: 40px;
    transition: padding-left 0.3s ease;
    cursor: pointer;
    justify-content: center;
  }
  img {
    margin-left: 1rem;
    width: 1.3rem;
    height: 1.3rem;
    flex-shrink: 0;
  }
  span:hover {
    background-color: rgb(250, 86, 34);
  }
}

.insightsCatAllContainer {
  display: flex;
  place-items: flex-start;
  align-items: flex-start;
  justify-content: center;
  gap: 70px;
  margin: 2rem 0;
  flex-wrap: wrap;
  padding: 1rem;
  .insightsCatCard {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    // margin: 0 auto;
  }
  .insightsCatCard:last-child {
    margin: 0;
  }
}

.insightsCatImgBox {
  width: 100%;
  width: 411px;
  height: 302px;
  overflow: hidden;
  border-top-right-radius: 40px;

  position: relative;
  img {
    border-top-right-radius: 40px;
    width: 411px;
    height: 302px;
    object-fit: cover;
  }
}

.insightsCatTextCard {
  color: #eff2ef;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 32px;
  width: 411px;
}

.insightsCatTextCard > p:nth-child(2) {
  height: 90px;
}
// -------------------------------------------mediaQurrey----------------------------------------------------------------

@media (max-width: 992px) {
  .insightsLanding > .banner > .title {
    font-size: 2rem;
    line-height: 2rem;
  }

  .insightsLanding > .banner > .title > span {
    font-size: 2rem;
    display: block;
  }
  .insightsLanding-BG .insightsLandingBGBox {
    height: 0;
  }
  .fedInsightsContainer {
    flex-direction: column;
    padding: 1rem;
    gap: 8px;
    margin: 0;
  }
  .fedInsightsCard {
    height: 100%;
    width: 100%;
  }

  .fedInsightsImgBox {
    width: 100%;
    height: 243px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .insightsCatTextCard {
    color: #fffbfb;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
    width: 100%;
  }

  .fedInsightsTitle {
    width: 100%;
    color: #eff2ef;
    text-align: center;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
  }

  .fedInsightsTextCard {
    width: 100%;
    color: #eff2ef;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .fedsubcards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .fedInsightsSubCard {
    display: flex;
    flex-direction: row;
    gap: 20px;
    cursor: pointer;
    align-items: center;
    width: 100%;
    flex-shrink: 0;
  }

  .fedInsightssubImgBox {
    width: 50%;
    overflow: hidden;
    border-radius: 1.25rem;
    height: 10rem;
    position: relative;
    > img {
      border-radius: 1.25rem;
      width: 100%;
      // height: 10rem !important;
      height: 100%;
      transition: transform 1.2s;
    }
    > .fedInsightsPillCard {
      height: 22px;
      top: 7px;
      left: 7px;
      border-radius: 10.72px;
      font-size: 12px;
    }
  }

  .fedsubInsightsTextCardContainer {
    width: 50%;
  }

  .fedsubInsightsTextCard {
    width: 100%;
  }
  .fedInsightsTimeStamp {
    font-size: 10px;
    margin: 0;
  }
  .fedsubInsightsTextCard p {
    margin: 0;
    font-size: 16px;
  }

  .insightsCatContainer {
    margin: 0rem 0 2rem 0;
    padding: 3rem 0 3rem 0;
  }

  .insightsCatTextCard > p:nth-child(2) {
    height: auto;
  }

  .fedsubInsightsTextCard p:first-child {
    font-size: 0.7rem;
  }
  .insightsLoadMore {
    span {
      color: #fffefc;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      width: 167px;
      height: 44px;
      padding: 0px;
      line-height: 44px;
    }
    img {
      width: 1rem;
      height: 1rem;
      margin-left: 5px;
    }
  }

  .insightsCatAllContainer {
    padding: 2rem;
    gap: 40px;
    .insightsCatCard {
      display: flex;
      width: 100%;
      .insightsCatImgBox {
        width: 100%;
        height: 243px;
        > img {
          height: 302px;
          width: 100%;
        }
      }
    }
  }

  .insightsLanding-BG {
    padding: 0 1.5rem;
    .insightsLandingImg {
      display: none;
    }
  }

  .fedsubInsightsTextCardContainer {
    gap: 1rem;
  }
}

// -------------------------------------993-1366----------------------------------------------------------------

@media (min-width: 993px) and (max-width: 1366px) {
  .fedInsightsPillCard {
    top: 1.5rem;
  }
  .insightsCatTextCard {
    font-size: 16px;
    font-weight: 400;
    margin-top: 32px;
    width: 100%;
    text-wrap: wrap;
  }
  .insightsCatAllContainer {
    gap: 30px;
  }
  .fedInsightsContainer {
    margin: 1.5rem 30px;
    gap: 43px;
  }
  .fedsubInsightsTextCard {
    width: 275px;
  }
  .fedInsightsImgBox img {
    height: 100%;
    object-fit: cover;
  }
  .fedInsightssubImgBox img {
    object-fit: fill;
    width: 18rem;
    height: 14.8rem;
  }
  .insightsLoadMore span {
    display: flex;
    width: fit-content;
    padding: 0 40px;
    font-size: 18px;
    height: 44px;
    align-items: center;
  }
}
