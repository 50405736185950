// eslint-disable-next-line
.careerBannerContainer {
  // height: 34.25rem;
  .career_banner {
    height: 447px;
    opacity: 80%;
    position: absolute;
    z-index: -10;
    width: 100%;
  }
  .careerBannerContent {
    // color: white;
    display: inline-flex;
    width: 100%;
    padding: 7.5rem;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
  }
  .careerBannerTitle {
    color: #e3e2ff;
    text-align: center;
    font-family: Poppins;
    font-size: 3.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 4.5rem; /* 116.129% */
  }
  .careerBannerTagline {
    color: #efedfd;
    text-align: center;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  .careerOpportunitiesBtn > .interactiveBtn > span {
    font-size: 1.1rem;
  }
}

.talentContainerParent {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 120px;
}

.talentContainer {
  display: flex;
  width: 1440px !important;
  // height: 42.9375rem;
  // background-image: url("../assets/InsightsLanding/fedInsights_bg.png");
  // background-position: center;
  // background-size: cover;
  background-repeat: no-repeat;
  padding: 0 5rem;
  justify-content: center;
  align-items: flex-start;
  gap: 9.375rem;
  .career_banner {
    opacity: 80%;
    position: absolute;
    z-index: -10;
    left: 0;
  }
  .talentContentLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    > .talentContentLeftTitle {
      color: #fff;
      font-family: Poppins;
      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 3.25rem; /* 123.81% */
      letter-spacing: -0.0525rem;
      width: 22.5625rem;
      min-width: 22.5625rem;
      max-width: 22.5625rem;
    }
    > .talentContentLeftTagline {
      color: #efedfd;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      width: 29.875rem;
      > p {
        margin: 0;
      }
      > p:nth-child(2) {
        margin-top: 1rem;
      }
    }
  }
  .talentContentRight {
    display: grid;
    row-gap: 0;
    height: 27.9375rem;
    padding-top: 32px;
    align-items: flex-start;
    gap: 2rem;
    flex: 1 0 0;
    > .talentItemTypeContainer {
      display: flex;
      // justify-content: space-between;
      align-items: flex-start;
      flex-shrink: 0;
    }
    .talentSeperator {
      width: 100%;
      opacity: 20%;
      height: 0.0625rem;
      background: #fff;
    }
    .talentItemType {
      font-family: Poppins;
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.875rem; /* 107.143% */
      letter-spacing: -0.035rem;
      background: var(
        --Ngenux-gradient,
        linear-gradient(97deg, #fd95ff 2.52%, #9747ff 104.52%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0;
      width: 40%;
      word-wrap: break-word;
      margin-right: 1%;
    }
    .talentItemTypeContent {
      color: #fff;
      font-family: Poppins;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1.75rem; /* 140% */
      width: 60%;
      flex-shrink: 0;
      margin-bottom: 0;
      word-wrap: break-word;
    }
  }
}

.whyNgenuxContainer {
  display: flex;
  width: 100%;
  padding: 0rem 5rem 5rem 5rem;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  .whyNgenuxTitle > p {
    margin: 0;
    font-family: Poppins;
    font-style: normal;
    text-align: center;
  }
  .whyNgenuxTitle > p:nth-child(1) {
    color: #fff;
    font-size: 2.625rem;
    font-weight: 600;
    line-height: 140.5%; /* 3.68813rem */
    letter-spacing: -0.0525rem;
  }
  .whyNgenuxTitle > p:nth-child(2) {
    color: #efedfd;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: normal;
  }

  .whyNgenuxVideo {
    .whyNgenuxVideoBanner {
      width: 90rem;
      height: 38.8125rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
  }
}

.ourMetricsContainer {
  display: flex;
  padding: 0rem 5rem 5rem 5rem;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  .ourMetricsTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    > p {
      margin: 0;
      text-align: center;
      font-family: Poppins;
      font-style: normal;
    }
    > p:nth-child(1) {
      color: #ff988a;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 140.5%; /* 2.1075rem */
      letter-spacing: -0.03rem;
    }
    > p:nth-child(2) {
      color: #fff;
      font-size: 2.25rem;
      font-weight: 600;
      line-height: 3.25rem; /* 144.444% */
      letter-spacing: -0.045rem;
    }
  }
  .ourMetricsContent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    .ourMetricsContentItem {
      display: flex;
      width: 33%;
      height: 15.4375rem;
      padding: 3.125rem 8.25rem 3.6875rem 8.25rem;
      flex-direction: column;
      align-items: center;
      gap: 2.25rem;
      border-radius: 1.5rem;
      background: linear-gradient(
        185deg,
        rgba(142, 97, 189, 0.2) -26.06%,
        rgba(97, 73, 164, 0.2) 121.46%
      );
      > img {
        width: 4.5rem;
        height: 4.5rem;
        flex-shrink: 0;
      }
      > p {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.875rem; /* 107.143% */
        letter-spacing: -0.035rem;
        margin: 0;
      }
    }
  }
}

.openPositionsContainer {
  display: flex;
  padding: 0rem 5rem 5rem 5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;
  position: relative;
  > img {
    width: 30rem;
    height: 18rem;
    flex-shrink: 0;
    border-radius: 14rem;
    background: linear-gradient(90deg, #9f64ff 0%, #4b3be4 71.42%);
    filter: blur(90px);
    z-index: -1;
    position: absolute;
  }
  .openPositionsTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    > p {
      font-family: Poppins;
      font-style: normal;
      margin: 0;
    }
    > p:nth-child(1) {
      color: #ff988a;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 140.5%; /* 2.1075rem */
      letter-spacing: -0.03rem;
    }
    > p:nth-child(2) {
      color: #fff;
      font-size: 2rem;
      font-weight: 600;
      line-height: 3.25rem; /* 123.81% */
      letter-spacing: -0.0525rem;
    }
  }

  .openPositionsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    .openPositionsContentBox {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: flex-start;
      gap: 1.5rem;
      flex-wrap: wrap;
    }
    .openPositionsLoadMore {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 3.25rem;
      span {
        background-color: rgb(102, 91, 216);
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 132%;
        text-transform: capitalize;
        color: rgb(255, 254, 252);
        padding-top: 20px;
        width: 251px;
        height: 72px;
        border-radius: 40px;
        transition: padding-left 0.3s ease;
        cursor: pointer;
        justify-content: center;
      }
      img {
        margin-left: 1rem;
        width: 1.3rem;
        height: 1.3rem;
        flex-shrink: 0;
      }
      span:hover {
        background-color: rgb(250, 86, 34);
      }
    }
  }
}

.insightsMiniContainer {
  display: flex;
  padding: 0rem 5rem 5rem 5rem;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  .insightsMiniTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    > p {
      margin: 0;
      text-align: center;
      font-family: Poppins;
      font-style: normal;
    }
    > p:nth-child(1) {
      color: #ff988a;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 140.5%; /* 2.1075rem */
      letter-spacing: -0.03rem;
    }
    > p:nth-child(2) {
      color: #fff;
      font-size: 2.25rem;
      font-weight: 600;
      line-height: 3.25rem; /* 144.444% */
      letter-spacing: -0.045rem;
    }
  }
  .insightsMiniContent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    margin-bottom: 20%;
    .insightsMiniContentItems {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 1.5rem;
      > .insightsCatCard {
        width: 33%;
      }
      .fedInsightsTimeStamp {
        display: none;
      }
    }
  }
}

.gallery-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 90vh;
  // background: #f2e9ff;
  gap: 1.5rem;
  flex-shrink: 0;
  margin-bottom: 5%;
  .career-gallery {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 90%;
    overflow: hidden;
    .gallery-img {
      width: 8rem;
      height: 30rem;
      margin: 10px;
      border-radius: 10px;
      position: relative;
      transition: width 1.5s;
      cursor: pointer;
      background: url("../assets/career/Image\ 1.png");
      background-size: cover;
      background-position: bottom right;
      overflow: hidden;
    }
    .gallery-img-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
      position: absolute;
      bottom: 0;
      opacity: 0;
      width: 30rem;
      height: 30rem;
      width: 480px;
      // background: linear-gradient(90deg, #A08472 -4.22%, rgba(142, 111, 91, 0.52) 57.92%, rgba(140, 96, 67, 0) 96.36%);
      justify-content: end;
      padding: 0 5% 5%;
      border-radius: 10px;
      flex-shrink: 0;
      > p {
        margin: 0;
        font-family: Poppins;
        font-style: normal;
        color: #fff;
        font-weight: 400;
      }
      > p:nth-child(1) {
        font-size: 1.6rem;
        line-height: 2.79763rem; /* 134.286% */
      }
      > p:nth-child(2) {
        font-size: 1rem;
        line-height: normal;
      }
    }
    .gallery-img:nth-child(2) {
      background: url("../assets/career/Image\ 2.png");
      background-size: cover;
      background-position: bottom right;
      height: 25rem;
      transition: 1s;
    }
    .gallery-img:nth-child(3) {
      background: url("../assets/career/Image\ 3.png");
      background-size: cover;
      background-position: bottom right;
    }
    .gallery-img:nth-child(4) {
      background: url("../assets/career/Image\ 4.png");
      background-size: cover;
      background-position: bottom right;
      height: 28rem;
      transition: 1s;
    }
    .gallery-img:nth-child(5) {
      background: url("../assets/career/Image\ 5.png");
      background-size: cover;
      background-position: bottom right;
    }

    .gallery-img-first {
      width: 846px;
    }
    .gallery-img-first .gallery-img-content:nth-child(1) {
      opacity: 1;
    }

    .gallery-img-clicked {
      width: 846px;
      height: 30rem !important;
      .gallery-img-content {
        opacity: 1;
        height: 30rem;
      }
    }
  }
}
// -------------------------------------------mediaQuery----------------------------------------------------------------
@media (min-width: 993px) and (max-width: 1366px) {
  .gallery-container .career-gallery .gallery-img-content > p:nth-child(1) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .whyNgenuxContainer .whyNgenuxVideo .whyNgenuxVideoBanner {
    width: auto;
    height: 470px;
  }
  .ourMetricsContainer .ourMetricsContent .ourMetricsContentItem {
    width: 32%;
  }
  .talentContainer .talentContentRight {
    height: auto;
  }
  .careerBannerContainer .careerBannerTitle {
    font-size: 55px;
  }
  .openPositionsContainer,
  .ourMetricsContainer,
  .whyNgenuxContainer,
  .talentContainer,
  .insightsMiniContainer {
    padding: 0 30px 80px;
  }
  .gallery-container .career-gallery {
    width: 100%;
    padding: 0 30px;
  }
  .talentContainer .talentContentLeft > .talentContentLeftTagline {
    width: 25rem;
  }
  .whyNgenuxVideo {
    width: 100%;
  }
  .insightsMiniContainer .insightsMiniContent {
    margin-bottom: 0;
  }
  .talentContainerParent {
    padding-bottom: 0;
  }
  .talentContainer .talentContentRight > .talentItemTypeContainer {
    flex-direction: column;
    gap: 0.5rem;
  }
  .talentContainer .talentContentRight .talentItemType {
    width: auto;
    font-size: 24px;
  }
  .talentContainer .talentContentRight .talentItemTypeContent {
    width: auto;
  }
}
@media (max-width: 992px) {
  .careerBannerContainer {
    .careerBannerContent {
      padding: 15% 5%;
      gap: 1rem;
    }
    .career_banner {
      opacity: 100%;
      height: auto;
    }
    .careerBannerTitle {
      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.5rem; /* 125% */
      letter-spacing: -0.02rem;
    }
    .careerBannerTagline {
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.75rem; /* 175% */
      letter-spacing: -0.02rem;
    }
    .careerOpportunitiesBtn > .interactiveBtn {
      display: flex;
      align-items: center;
      padding: 0.75rem 1.5rem;
      width: auto;
      justify-content: center;
    }
    .careerOpportunitiesBtn > .interactiveBtn > span {
      font-size: 0.8rem;
      margin-right: 6px;
    }
    .careerOpportunitiesBtn > .interactiveBtn > img {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .talentContainerParent {
    padding-bottom: 32px;
  }

  .talentContainer {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
    height: auto;
    .talentContentLeft {
      display: flex;
      flex-direction: column;
      gap: 0.1rem;
      align-items: center;
      > .talentContentLeftTitle {
        font-size: 1.25rem;
        letter-spacing: -0.025rem;
        text-align: center;
      }
      > .talentContentLeftTagline {
        font-size: 1rem;
        width: auto;
        max-width: fit-content;
        min-height: auto;
        text-align: center;
        > p:nth-child(2) {
          margin-top: 0;
        }
      }
    }
    .career_banner {
      top: 39%;
      width: 10rem;
      height: 40rem;
      left: 50%;
      opacity: 100%;
    }

    .talentContentRight {
      display: grid;
      row-gap: 1rem;
      width: 100%;
      align-items: center;
      flex: auto;
      height: auto;
      justify-items: center;
    }
    .talentItemTypeContainer {
      display: flex;
      width: 21.4375rem;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      .talentItemType {
        text-align: center;
        width: 100%;
        margin-right: auto;
        font-size: 1.25rem;
      }
      .talentItemTypeContent {
        width: auto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 300;
        line-height: 1.25rem; /* 166.667% */
        text-align: center;
      }
    }
  }

  .whyNgenuxContainer {
    padding: 1rem;
    gap: 1rem;
    .whyNgenuxTitle > p {
      margin: 0;
      font-family: Poppins;
      font-style: normal;
      text-align: center;
    }
    .whyNgenuxTitle > p:nth-child(1) {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.75rem;
      letter-spacing: -0.025rem;
    }
    .whyNgenuxTitle > p:nth-child(2) {
      color: #efedfd;
      font-size: 1rem;
      font-weight: 400;
      line-height: normal;
      margin-top: 1rem;
    }

    .whyNgenuxVideo {
      width: 90%;
      .whyNgenuxVideoBanner {
        width: 100%;
        height: 10rem;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        > img:nth-child(1) {
          width: 100%;
          height: 10rem;
          object-fit: cover;
          border-radius: 20px;
        }
        > img:nth-child(2) {
          position: absolute;
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }

  .ourMetricsContainer {
    margin-top: 2rem;
    gap: 1rem;
    .ourMetricsTitle {
      width: max-content;
      gap: 0;
      > p:nth-child(1) {
        font-size: 1rem;
      }
      > p:nth-child(2) {
        font-size: 1.25rem;
      }
    }
    .ourMetricsContent {
      flex-wrap: wrap;
      .ourMetricsContentItem {
        display: flex;
        width: 21.4375rem;
        height: 10.375rem;
        // padding: 2rem 7.4375rem;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        gap: 1rem;
      }
    }
  }

  .openPositionsContainer {
    padding: 0rem 1rem;
    position: relative;
    > img {
      width: 17.8125rem;
      height: 10.78888rem;
      flex-shrink: 0;
      border-radius: 17.8125rem;
      background: linear-gradient(90deg, #9f64ff 0%, #4b3be4 71.42%);
      filter: blur(100px);
      z-index: -1;
      position: absolute;
    }
    .openPositionsTitle {
      align-items: center;
      > p:nth-child(1) {
        font-size: 1rem;
        font-weight: 500;
        line-height: 140.5%; /* 1.405rem */
        letter-spacing: -0.02rem;
        text-align: center;
      }
      > p:nth-child(2) {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.75rem; /* 140% */
        letter-spacing: -0.025rem;
        text-align: center;
      }
    }

    .openPositionsContent {
      width: 100%;
      align-items: stretch;
      gap: 1rem;
      margin-bottom: 2rem;
      .openPositionsLoadMore {
        span {
          color: #fffefc;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          width: 167px;
          height: 44px;
          padding: 0px;
          line-height: 44px;
        }
        img {
          width: 1rem;
          height: 1rem;
          margin-left: 5px;
        }
      }
    }
    .openPositionsContentBox {
      flex-direction: column;
    }
    .openPositionsCard {
      width: 100%;
      .openPositionDescription {
        display: none;
      }
    }

    .openPositionLoadMore {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .openPositionsCardApply {
      // gap: 3rem;
      > p {
        font-size: 0.75rem;
      }
      div.interactiveBtn {
        // width: 40%;
        padding: 0.2rem;
      }
    }
  }

  .insightsMiniContainer {
    margin-top: 2rem;
    gap: 1rem;
    padding: 0 2rem;
    .insightsMiniTitle {
      gap: 0;
      > p {
        margin: 0;
      }
      > p:nth-child(1) {
        font-size: 1rem;
      }
      > p:nth-child(2) {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.75rem; /* 140% */
        letter-spacing: -0.025rem;
      }
    }

    .insightsMiniContentItems {
      flex-direction: column;
    }
    .insightsCatCard {
      width: 100% !important;
    }
  }

  .gallery-container {
    height: 18rem;
    margin-bottom: 0;
    justify-content: flex-start;
    gap: 0;
    display: block;
    .career-gallery {
      display: flex;
      overflow-x: auto;
      margin: 0 auto;
      width: auto;
      justify-content: flex-start;
      .gallery-img {
        min-width: 22rem !important;
        height: 15rem !important;
        .gallery-img-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          opacity: 1;
          height: 15rem;
          width: 13rem;
          > p:nth-child(1) {
            font-size: 0.875rem;
            line-height: normal;
          }
          > p:nth-child(2) {
            font-size: 0.8rem;
            line-height: normal;
            width: 20rem;
          }
        }
      }
    }
  }
}
