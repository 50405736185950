.caseStudyBannerContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 795px;
}

.caseStudyChildContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: url("../assets/caseStudyPage/bgGradient.png");
  background-position: center;
  background-size: cover;
  height: 901px;
  width: 100%;
  top: -106px;
}

.caseStudybnrHeading {
  color: #fff;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px; /* 123.81% */
  letter-spacing: -0.84px;
  width: 846px;
  max-width: 1280px;
  margin-bottom: 32px;
}

.caseStudyBanner {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 156px;
  padding: 0px 297px 48px 297px;
}

.bannerCaseStudyPills {
  color: #ff988a;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.08px;
  text-transform: uppercase;
  margin-bottom: 48px;
}

.solutionsCaseStudyImg {
  width: 846px;
  height: 421.017px;
  margin-bottom: 48px;
}
.solutionsCaseStudyImg img {
  width: 846px;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 24px;
}

.impactBoxesContainer {
  width: 846px;
  flex-wrap: wrap;
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
}

.impactBox {
  display: flex;
  min-width: 411px;
  max-width: 411px;
  padding: 72px 24px 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 24px;
  background: linear-gradient(
    185deg,
    rgba(142, 97, 189, 0.2) -26.06%,
    rgba(97, 73, 164, 0.2) 121.46%
  );
}

.solutionsParaText {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 138.523%; /* 24.934px */
  width: 846px;
  margin-bottom: 24px;
}
.solutionsParaTextCard {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 138.523%; /* 24.934px */
}

.caseStudyContentContainer {
  background: #0e0320;
  padding: 80px 297px 48px 297px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.solutionsImpactImg {
  width: 100%;
}
.contentCaseStudyBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  width: 846px;
  color: #e3e2ff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  img {
    width: 846px;
  }
}
.caseStudyContenTitle {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  margin-bottom: 24px;
  display: flex;
  width: 846px;
}
.caseStudyCard2Container {
  display: flex;
  flex-direction: column;
  background: #0e0320;
  gap: 32px;
  padding: 0px 297px 48px 297px;
  width: 100%;
  align-items: center;
}

.caseStudyCardSubContainer {
  display: flex;
  gap: 24px;
}

.CaseStudyCard2 {
  position: relative;
  display: flex;
  flex-direction: column;
}
.caseStudyCard2ContainerTitle {
  color: #e3e2ff;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%; /* 59.01px */
  letter-spacing: -0.84px;
  display: flex;
  width: 846px;
}
.caseStudyCard2Title {
  color: #9781ee;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
  text-transform: capitalize;
  margin-bottom: 16px;
}

.caseStudyCard2Description {
  width: 373px;
  color: #eff2ef;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 33.6px */
}

.caseStudyCard2ImgContainer {
  width: 410px;
  height: 302px;
  margin-bottom: 32px;
}

.caseStudyCard2Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.caseStudyCard2StickerImgContainer {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: white;
  border-radius: 50%;
  width: 91px;
  height: 91px;
}

.caseStudyCard2StickerImg {
  position: absolute;
  width: 53.831px;
  height: 55.113px;
  flex-shrink: 0;
  top: 17.94px;
  right: 17.94px;
}

// ====================================================================media===================================================
@media (min-width: 993px) and (max-width: 1366px) {
  .caseStudyContentContainer,
  .caseStudyCard2Container,
  .caseStudyBanner {
    padding-left: 30px;
    padding-right: 30px;
  }
  .caseStudyCard2Container {
    align-items: flex-start;
  }
  .caseStudyContentContainer {
    padding-top: 0;
  }
}

@media (max-width: 992px) {
  .caseStudyChildContainer {
    height: 468px;
  }
  .caseStudyBannerContainer {
    height: 362px;
  }
  .caseStudyBanner {
    top: 120px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0px;
  }
  .caseStudybnrHeading {
    width: 343px;
    flex-shrink: 0;
    color: #fff;
    font-family: Poppins;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
    margin-bottom: 12px;
  }
  .bannerCaseStudyPills {
    margin-bottom: 16px;
  }
  .solutionsCaseStudyImg {
    margin-bottom: 32px;
    width: 343px;
    height: 182px;
    margin-bottom: 32px;
  }
  .solutionsCaseStudyImg img {
    width: 343px;
    height: 182px;
  }
  .impactBoxesContainer {
    flex-direction: column;
    display: flex;
    min-width: 343px;
    max-width: 343px;
  }
  .impactBox {
    padding: 60px 20px 24px 20px;
    margin-bottom: 20.03px;
    min-width: 343px;
    max-width: 343px;
  }
  .caseStudyContentContainer {
    padding: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .solutionsParaTextCard {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .caseStudyContenTitle {
    width: 343px;
    color: #e3e2ff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
    letter-spacing: -0.4px;
    margin-bottom: 16px;
  }
  .solutionsParaText {
    width: 343px;
    color: #e3e2ff;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 200% */
    align-self: stretch;
  }
  .contentCaseStudyBox {
    margin-bottom: 24px;
    width: 343px;
    color: #e3e2ff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 200% */
  }
  .contentCaseStudyBox img {
    width: 343px;
  }
  .caseStudyCardSubContainer {
    flex-direction: column;
  }

  .caseStudyCard2ImgContainer {
    width: 343px;
    height: 252px;
    margin-bottom: 24px;
  }

  .caseStudyCard2Img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  .CaseStudyCard2 {
    width: 343px;
    position: relative;
  }
  .caseStudyCard2ContainerTitle {
    width: 343px;
    margin-bottom: 23.67px;
    color: #e3e2ff;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 140.5%; /* 36.53px */
    letter-spacing: -0.52px;
  }
  .caseStudyCard2Container {
    gap: 0px;
    padding-top: 0px;
    padding: 1rem;
    padding-bottom: 48px;
  }
  .caseStudyCard2Title {
    margin-bottom: 11.73px;
    color: #9781ee;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    text-transform: uppercase;
    width: 100%;
  }
  .caseStudyCard2Description {
    width: 100%;
    color: #eff2ef;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
  }
  .caseStudyCard2StickerImgContainer {
    position: absolute;
    top: 12.55px;
    right: 12.05px;
    background-color: white;
    border-radius: 50%;
    width: 76.129px;
    height: 76.129px;
    flex-shrink: 0;
  }

  .caseStudyCard2StickerImg {
    position: absolute;
    width: 45.034px;
    height: 46.106px;
    flex-shrink: 0;
    top: 15.01px;
    right: 15.01px;
  }
}
