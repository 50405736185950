.heroContainer {
  background: #0c0225;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100% !important;
  top: 0px;
  background-color: black;
  width: 100%;
  overflow: hidden;
}

.carousel-indicators button {
  background-image: url("../assets/carouselIndicator.svg") !important;
  background-position: center;
  background-size: contain;

  border-radius: 100%;
  background-color: transparent !important;
  bottom: 200px !important;
}
.carousel-indicators [data-bs-target] {
  height: 22px !important;
  width: 22px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
  bottom: 200px !important;
}
.carousel-indicators {
  bottom: 50px !important;
  gap: 20px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 48px !important;
  height: 48px !important;
}

.carousel-control-prev-icon {
  background-image: url("../assets/leftArrow.svg") !important;
  transform: rotate(180deg);
  margin-left: -36px !important;
  display: block !important;
}

.carousel-control-next-icon {
  background-image: url("../assets/leftArrow.svg") !important;
}
.carousel-control-next {
  z-index: 0 !important;
  justify-content: end !important;
  left: calc(100% - 200px) !important;
  width: 85px !important;
  height: fit-content !important;
  top: 88% !important;
  display: block !important;
  margin-left: 12px;
}
.carousel-control-prev {
  z-index: 0 !important;
  justify-content: space-between !important;
  left: calc(100% - 200px) !important;
  height: fit-content !important;
  top: 88% !important;
  width: 65px !important;
}

// #homeBanner {
//   display: flex;
//   width: 100%;
//   flex-direction: column;

// }

.heroContent {
  width: 100%;
  height: 798px;
  background: #090029 !important;
}
.heroContainerParent {
  height: 692px;
  display: flex;
  justify-content: center;
  background: #0c0225;
}
.heroTextContainer {
  color: #fff;
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -1.24px;
  width: 50%;
  word-break: keep-all;
}

.heroSubText {
  color: #e9d4ff;
  font-weight: normal;
  font-size: 24px;
  width: 50%;
  text-align: left;
}

.aiData {
  color: #e3e3e3;
  font-family: Poppins;
  font-size: 62px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -1.24px;
}
.btnLocation {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 254px;
  left: 7%;
  gap: 47.75px;
}

.showMob {
  display: none;
}
.caseStudyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
  padding-top: 93.3px;
  padding-left: 80px;
  padding-right: 80px;
  background: #0c0225;
  background-image: url("../assets/homepage/Union.png");
  background-position: center center;
  background-size: cover;
}
ol,
ul {
  padding-left: 0rem !important;
}
.caseStudyCardDesk {
  position: relative;
  height: 765.5px;
  width: 1280px;
}

.caseStudyCardMob {
  display: none;
}
.logo_card {
  position: absolute;
  top: 1px;
  right: 0px;
}
.cs_details {
  position: absolute;
  bottom: 0px;
  right: 0px;
  bottom: 0px;
}

.caseCardImgDeskContainer {
  position: absolute;
  top: 158.5px;
  left: 48px;
  width: 546px;
  height: 351px;
  flex-shrink: 0;
}

.caseCardImgDesk {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.stickerCardImgDesk {
  position: absolute;
  right: 24px;
  top: 19.5px;
}

.oneImgDesk {
  position: absolute;
  top: 564.5px;
  left: 48px;
}
.twoImgDesk {
  position: absolute;
  top: 564.5px;
  left: 438px;
}
.threeImgDesk {
  position: absolute;
  top: 564.5px;
  left: 869px;
}

.caseTextBottom {
  display: flex;
  position: absolute;
  top: 607.5px;
  left: 48px;
  gap: 42px;
}

.underText {
  color: #303030;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 138.523%; /* 24.934px */
  width: 347px;
}
.underTextLast {
  color: #303030;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 138.523%; /* 24.934px */
  width: 347px;
  left: 868px;
  margin-left: 42px;
}

.mainCaseTextbox {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 590px;
  height: 327px;
  top: 162.5px;
  right: 70px;
  gap: 15px;
  color: #000;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px; /* 140% */
}
.subMainText {
  color: #404040;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.casemainText {
  top: -85px;
  position: absolute;
  color: #ff988a;
  width: 100%;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140.5%; /* 33.72px */
  letter-spacing: -0.48px;
}

.casemainSubText {
  position: absolute;
  color: #fff;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px; /* 150% */
  letter-spacing: -0.72px;
  width: 711px;
  top: -41px;
}
.ourSuccessText {
  display: none;
}
.caseStudyTextMob {
  display: none;
}

.caseStudyDeskBtn {
  position: absolute;
  bottom: -36px;
  left: 533px;
}

.testimonialContainer {
  background: #0c0225;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  background-image: url("../assets/homepage/backgroundArrow.png");
  background-position: center center;
  background-size: contain;
}

.whatwebringImgMob {
  display: none;
}

.clientText {
  color: #ff988a;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140.5%; /* 33.72px */
  letter-spacing: -0.48px;
  margin-bottom: 12px;
}

.impactText {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%; /* 59.01px */
  letter-spacing: -0.84px;
  margin-bottom: 24px;
}

.capabilitiesContainer {
  padding: 120px 80px 120px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #0c0225;
}

.capabilitiesTxt {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%; /* 59.01px */
  letter-spacing: -0.84px;
  margin-bottom: 16px;
}

.designedTxt {
  color: #efedfd;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  max-width: 925px;
  margin-bottom: 80px;
}
.capabilitiesCardContainer {
  display: flex;
  width: 1279px;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  row-gap: 23px;
  flex-wrap: wrap;
  margin-bottom: 48px;
}

.homePageBanner {
  width: 100%;
  background-image: url("../assets/homepage/banner.png");
  background-position: center center;
  background-size: cover;
  height: 651px;
  fill: linear-gradient(
    155deg,
    rgba(255, 255, 255, 0.2) 18.81%,
    rgba(255, 255, 255, 0) 69.6%
  );

  filter: drop-shadow(
    0px 2.250607967376709px 33.75912094116211px rgba(0, 0, 0, 0.25)
  );
}

.traingleBox {
  width: 250px;
  height: 296px;
  background-image: url("../assets/Frame.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}
.traingleBoxNoarrow {
  width: 250px;
  height: 296px;
  text-align: center;
}

.bannerHeading {
  margin-top: 130px;
  color: #1c193d;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px; /* 128.571% */
  display: inline-block;
  text-wrap: nowrap;
}

.bannerSubHeading {
  color: #1c193d;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
  text-wrap: nowrap;
}

.dataBannerContainer {
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
}
.staticsBanner {
  display: flex;
  gap: 104px;
  justify-content: center;
}

.staticsContent {
  flex-direction: column;
}
.staticsHeading {
  color: #38327e;
  font-family: Poppins;
  font-size: 100px;
  font-style: normal;
  font-weight: 700;
  line-height: 120px; /* 120% */
  letter-spacing: -2px;
}
.staticsContent {
  color: #1c193d;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 326px;
}

.whatwebringContainer {
  display: flex;
  justify-content: center;
  background: #090029 !important;
  position: relative;
  gap: 9rem;
  height: 1524px;
  padding-bottom: 122px;
}
.whatwebringFill {
  position: absolute;
  height: 1143px;
}

.whatwebringImg {
  width: 470px;
  position: sticky;
  top: 156px;
}
.whatwebringScrollSection {
  display: flex;
  flex-direction: column;
}

.whatwebringHeading {
  color: #ff988a;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140.5%; /* 33.72px */
  letter-spacing: -0.48px;
  margin-bottom: 12px;
}

.whatwebringSubHeading {
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  line-height: 36px; /* 123.81% */
  letter-spacing: -0.84px;
  width: 613px;
  margin-bottom: 48px;
}

.whatweBringCardContainer {
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin-bottom: 48px;
  height: 1143px;
}

////------------------------------1221-1366------------------------------------------------------------------------------------
@media (min-width: 993px) and (max-width: 1366px) {
  .casemainText {
    top: -40px;
    font-size: 18px;
  }
  .whatwebringFill {
    width: 100%;
  }
  .homePageBanner {
    width: 100%;
    height: 600px;
  }
  .designedTxt {
    margin-bottom: 48px;
  }
  .staticsBanner {
    gap: 0;
    padding: 0 24px;
  }

  .staticsDetails {
    font-size: 16px;
  }

  .capabilitiesCardContainer {
    width: 100%;
  }
  .staticsHeading {
    color: #38327e;
    font-family: Poppins;
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 120px;
    letter-spacing: -2px;
  }
  .heroContainer {
    position: absolute;
    height: auto;
    width: 100% !important;
    background: #0c0225;
  }

  .whatwebringContainer {
    display: flex;
    justify-content: center;
    background: #090029 !important;
    position: relative;
    gap: 30px;
    // height: 1524px;
    padding-bottom: 60px;
  }
  .logo_card {
    width: 500px;
  }

  .whatwebringImg {
    width: 100%;
    position: sticky;
    top: 156px;
    max-width: 300px;
  }

  .whatwebringHeading {
    color: #ff988a;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.5%; /* 33.72px */
    letter-spacing: -0.48px;
    margin-bottom: 12px;
  }

  .whatwebringSubHeading {
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    line-height: 32px; /* 123.81% */
    letter-spacing: -0.84px;
    width: 100%;
    max-width: 600px;
    margin-bottom: 30px;
  }

  .heroTextContainer {
    font-size: 40px;
    width: 50%;
  }
  .heroSubText {
    font-size: 20px;
  }
  .caseStudyContainer {
    padding: 0;
  }
  .caseStudyCardDesk {
    width: 945px;
  }
  .caseCardImgDeskContainer {
    width: 45%;
    left: 30px;
    top: 111px;
  }
  .mainCaseTextbox {
    width: 45%;
  }
  .caseStudyDeskBtn {
    bottom: -24px;
    left: 42%;
  }
  .casemainSubText {
    font-size: 22px;
    width: 450px;
    top: -9px;
    line-height: 1.5;
  }
  .capabilitiesContainer {
    padding: 80px 30px 120px;
  }
  .caseTextBottom {
    gap: 10px;
    width: 90%;
    justify-content: space-between;
    left: 30px;
    top: 568px;
  }
  .underText,
  .underTextLast {
    width: 254px;
    margin-left: 0;
  }
  .oneImgDesk {
    left: 30px;
    top: 520px;
  }
  .twoImgDesk {
    left: 330px;
    top: 520px;
  }
  .threeImgDesk {
    left: 630px;
    top: 520px;
  }
  .mainCaseTextbox {
    font-size: 24px;
    right: 30px;
    line-height: 32px;
    top: 111px;
  }
  .cs_details {
    width: 945px;
    height: 690px;
    object-fit: cover;
    border-radius: 1rem;
    border-top-right-radius: 0;
  }
}

//------------------------------media-qurrey------------------------------------------------------------------------------------

@media (max-width: 992px) {
  .heroContainer {
    position: absolute;
    height: auto;
    width: 100% !important;
    background: #0c0225;
  }

  .capabilitiesCardContainer {
    flex-direction: column;
    width: 343px;
    margin-bottom: 0px;
    margin-top: 32px;
  }

  .diveinBtn {
    z-index: 1;
  }

  .capabilitiesContainer {
    padding: 1rem;
    padding-bottom: 48px;
    padding-top: 48px;
  }

  .capabilitiesTxt {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
    margin-bottom: 0px;
  }

  .designedTxt {
    color: #efedfd;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 343px;
    margin-bottom: 0px;
  }
  .discoverBtn {
    margin-top: 32px;
  }
  .heroRes {
    width: 100%;
    height: 667px;
  }
  .carousel-indicators {
    display: none !important;
  }
  .heroContainerParent {
    height: 603px;
  }
  .heroTextContainer {
    width: 50%;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 125% */
    letter-spacing: -0.64px;
    z-index: 0;
    height: 12px;
  }

  .heroSubText {
    font-size: 14px;
    width: 90%;
    font-weight: normal;
    margin-top: -20px;
  }
  .aiData {
    color: #e3e3e3;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.64px;
  }
  .btnLocation {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0px;
    padding: 0px;
    left: 0;
    gap: 28.78rem;
    top: 12%;
    z-index: 1;
  }
  .showDesk {
    display: none;
  }
  .showMob {
    display: block;
  }
  .caseStudyCard {
    width: 100%;
    height: 867px;
  }

  .caseStudyCardDesk {
    display: none;
  }
  .caseStudyCardMob {
    display: block;
    position: relative;
    height: 867px;
    width: 343px;
  }
  .cardMobPeice1 {
    position: absolute;
    top: 2px;
    right: 0px;
  }
  .cardMobPeice2 {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  .caseStudyContainer {
    padding: 1rem;
  }
  .cardElemMob {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 48px;
    gap: 24px;
    padding: 1rem;
    align-items: center;
  }
  .caseCardImgMob {
    width: 311px;
    height: 199px;
    border-radius: 8px;
  }
  .cardMaintextMob {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 138.889% */
  }
  .containerImgCaseCardMob {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .caseStudyTextMob {
    display: block;
    color: #ff988a;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.5%; /* 22.48px */
    letter-spacing: -0.32px;
    margin-bottom: 8px;
  }

  .ourSuccessText {
    display: block;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
    margin-bottom: 24px;
  }

  .clientText {
    color: #ff988a;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.5%; /* 22.48px */
    letter-spacing: -0.32px;
    margin-bottom: 8px;
  }

  .impactText {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
    width: 343px;
    padding-bottom: 24px;
  }

  .homePageBanner {
    height: 772px;
  }
  .staticsBanner {
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }
  .bannerHeading {
    color: #1c193d;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    margin-top: 65px;
  }
  .bannerSubHeading {
    color: #1c193d;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  }
  .traingleBox {
    width: 131px;
    height: 100%;
  }
  .traingleBoxNoarrow {
    width: 131px;
    height: 100%;
  }
  .dataBannerContainer {
    height: 137px;
    align-items: center;
  }

  .staticsHeading {
    color: #38327e;
    font-family: Poppins;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px; /* 114.286% */
    letter-spacing: -1.12px;
  }

  .staticsContent {
    color: #1c193d;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    width: 145px;
    line-height: 20px; /* 125% */
  }
  .whatwebringFill {
    width: 100%;
    height: 100%;
  }

  .whatwebringContainer {
    width: 100%;
    height: auto;
    position: relative;
    padding-bottom: 48px;
  }
  .whatwebringHeading {
    color: #ff988a;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.5%; /* 22.48px */
    letter-spacing: -0.32px;
  }
  .whatwebringSubHeading {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    line-height: 22px; /* 140% */
    letter-spacing: -0.4px;
    width: 343px;
  }
  .whatwebringScrollSection {
    width: 100%;
    align-items: center;
  }
  .whatwebringImg {
    display: none;
  }
  .imgWhatwebring {
    display: none;
  }
  .whatwebringImgMob {
    display: block;
    width: 200px;
    height: 200px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .carousel-control-next {
    z-index: 0 !important;
    justify-content: end !important;
    margin-right: 1rem;
  }
  .carousel-control-prev {
    z-index: 0 !important;
    justify-content: space-between !important;
    margin-left: 1rem;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    z-index: 1 !important;
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    z-index: 1 !important;
  }

  .carousel-control-next,
  .carousel-control-prev {
    display: none !important;
  }
  .testimonialContainer {
    height: 570px;
  }
}
