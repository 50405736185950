.menuItem {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  transition: padding-bottom 0.3s ease, transform 0.3s ease;
}
input:focus {
  border: none !important;
  outline: 1px solid #fff;
}
.menuItem.selected > a {
  opacity: 100%;
}

.menuItem > a {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  opacity: 50%;
}

.solutions {
  opacity: 50%;
}

.solutions:hover {
  opacity: 100%;
}

.solutionNavigate:first-child > img {
  opacity: 50%;
}

.menuItem:hover {
  transform: translateY(-4px); /* Example transform for the hover effect */
  padding-bottom: 4px;
}

.menuItem > a:hover {
  opacity: 100%;
}
.headingTileNormal {
  font-family: Poppins;
  font-size: 62px;
  font-weight: 300;
  line-height: 72px;
  letter-spacing: -0.02em;
  text-align: left;
}
.headingTileBold {
  font-family: Poppins;
  font-size: 62px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -0.02em;
  text-align: left;
}

.buttonTextLarge {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

.buttonTextSmall {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}
.sectionTitle {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: left;
}

.sectionHeading {
  font-family: Poppins;
  font-size: 42px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: -0.02em;
  text-align: left;
}
.sectionBody {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}
.footerTextNormal {
  font-family: Poppins;
  font-size: 54px;
  font-weight: 300;
  line-height: 46px;
  letter-spacing: -0.02em;
  text-align: left;
}
.footerTextLarge {
  font-family: Poppins;
  font-size: 62px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -0.02em;
  text-align: left;
}

.footerTextSmall {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 300;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.spotlightText {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}
.spotlightBody {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.res-headingTileNormal {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: center;
}
.res-headingTileBold {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: center;
}
.res-buttonTextLarge {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.res-buttonTextSmall {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.res-sectionTitle {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: center;
}
.res-sectionHeading {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: center;
}
.res-sectionBody {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.res-footerTextNormal {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: -0.02em;
  text-align: left;
}
.res-footerTextLarge {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.02em;
  text-align: left;
}
.res-footerTextSmall {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.res-spotlightText {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.res-spotlightBody {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.res-menuItem {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.res-menuItem div.menuItem.selected {
  opacity: 100%;
}

.res-menuItemSmall {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}
.pointerMouse {
  cursor: pointer;
}

.backgroundColorCardWhatwebring1 {
  background-color: (225, 197, 255, 1);
}

.backgroundColorCardWhatwebring2 {
  background-color: rgba(234, 204, 235, 1);
}

.backgroundColorCardWhatwebring3 {
  background-color: rgba(246, 210, 227, 1);
}
.backgroundColorCardWhatwebring4 {
  background-color: rgba(254, 209, 197, 1);
}

.headingRed {
  color: #ff988a;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140.5%; /* 33.72px */
  letter-spacing: -0.48px;
  margin-bottom: 12px;
}

.subHeading {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%; /* 59.01px */
  letter-spacing: -0.84px;
  color: rgba(255, 255, 255, 1);
}

.placeholderBg {
  background-color: black;
  color: black;
  height: 100vh;
  width: 100vw;
}

.errorTxt {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: black;
  width: 100%;
  height: 100vh;
}
.profileClass {
  width: 302px;
  height: 324px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  margin: 10px 15px;
}

.profileClass:hover {
  .gradCompany {
    display: block;
    opacity: 1;
  }
}

.gradCompany {
  display: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.profileDetailsClass {
  z-index: 1;
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 80%;
  justify-content: flex-end;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 40%,
    rgba(34, 33, 35, 0.15) 50.31%,
    rgba(25, 25, 26, 0.49) 62.36%,
    #232124 100%
  );
  color: #fff;
  font-family: Poppins;
  padding: 20px 20px;
  transition: transform 0.3s ease;
  /* If you want the div to grow from its center point: */
  transform-origin: center;
}
.profileDetailsClassTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.profileDetailsClassDes {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.profileDetailsClass:hover {
  height: 85%;
}

.profileIconClass {
  cursor: pointer;
  align-items: end;
  transform-origin: center left;
  transition: transform 0.3s ease, margin 0.3s ease;
}

.profileIconClass:hover {
  margin-top: 16px;
  transform: scale(1.5);
}

//************************* Media Query **********************************

@media (max-width: 1200px) {
  .profileClass {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 8px;
  }

  .profileDetailsClass {
    padding: 5px 20px;
  }

  .profileDetailsClassTitle {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
  }

  .profileDetailsClassDes {
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
  }
  .profileIconClass img:hover {
    transform: scale(1.1);
  }
}

////------------------------------993-1366------------------------------------------------------------------------------------
@media (min-width: 993px) and (max-width: 1366px) {
  .menuItem {
    font-size: 18px;
  }
  .ngenuxlogoDesk {
    width: 80%;
  }
  .profileClass {
    width: 290px;
    height: 312px;
  }
  .profileDetailsClassTitle {
    font-size: 20px;
  }
  .profileDetailsClassDes {
    font-size: 12px;
  }
  .profileDetailsClass {
    padding: 10px 20px;
  }
}

@media (max-width: 992px) {
  .gradCompany {
    display: none !important;
  }
  .profileClass {
    width: 163px;
    height: 176px;
  }
}
