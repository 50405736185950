.galleryContainer {
  position: absolute;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(12px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  top: -1506px;
}
.galleryContainer.close {
  display: none;
}
.galleryBtnClose{
  position: absolute;
  top: -1480px;

}
.galleryImgContainer{
  width: 1066.67px;
height: 480px;
margin-top: 5%


}

.galleryImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: contain;
  border-radius: 24px;

}

.arrowContainerCsr {
  display: flex;
  gap: 15px;
}

.closeBtnGallery {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
}

@media (max-width: 1385px) {
  
  .galleryImgContainer{
    width: 333px;
    height: 250px;
    object-fit: cover;



  }
  .galleryContainer{
    height: 100vh; 
    top: -1420px;
    
   }
   
  .galleryBtnClose{
    top: -1300px;
    width: 30px;
    height: 30px;
    right: 21px;

  }

  .galleryImg{
    width: 100%;
    height: 100%;

  }
}
