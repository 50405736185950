.csrBannerContainer {
  position: relative;
  height: 761px;
}
.csrBannerChildContainer {
  position: absolute;
  top: -106px;
  background: url("../assets/CsrPage/CsrBG.png");
  background-position: bottom;
  background-size: cover;
  width: 100%;
  height: 867px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.csrBannerEle {
  top: 186px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  align-items: center;
}

.csrBannerHeading {
  color: #e3e2ff;
  text-align: center;
  font-family: Poppins;
  font-size: 62px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 116.129% */
}

.csrBannerDescription {
  width: 1062px;
  color: #efedfd;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 150% */
}

.carouselCsrBannerImgContainer {
  width: 520px;
  height: 390px;
}

.carouselCsrBannerImg {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  object-fit: cover;
  overflow: hidden;
}

.carouselCsrBox {
  width: 100%;
  display: flex;
  overflow: hidden;
  gap: 24px;
}

.carouselContainerCsr {
  height: 500px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.makingMeaningfulDiffContainer {
  background: linear-gradient(180deg, #05010f 0%, #0e0320 100%);
  padding-top: 120px;
  display: flex;
  justify-content: center;
}

.makingMeaningfulDiffBox {
  display: flex;
  gap: 141px;
  align-items: center;
}
.rightSectionMakingMeaningfulDiffBox {
  display: flex;
  flex-direction: column;
  width: 510px;
  gap: 21px;
}

.makingTxtHeading {
  width: 510px;
  color: #fff;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 150% */
}

.makingTxtDescription {
  color: #efedfd;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 150% */
}

.makingDiffImg {
  width: 628px;
  height: 520px;
  flex-shrink: 0;
}

.caringContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #0e0320;
  align-items: center;
  padding-top: 120px;
}

.caringHeading {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 150% */
  margin-bottom: 16px;
}

.caringSubHeading {
  width: 1280px;
  max-width: 1280px;
  color: #efedfd;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 150% */
  margin-bottom: 48px;
}

.caringImgBox {
  display: flex;
  margin-bottom: 48px;
  gap: 24px;
}
.caringImg1 {
  width: 628px;
  height: 590px;
}

.caringImgEle {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.leftImgStrip {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.caringImg2 {
  width: 628px;
  height: 287px;
  border-radius: 24px;
  object-fit: cover;
}

.flexRowCaringImg {
  display: flex;
  gap: 24px;
}
.caringImg3 {
  width: 302px;
  height: 287px;
  border-radius: 24px;
  object-fit: cover;
}

.caringImg4 {
  width: 302px;
  height: 287px;
  border-radius: 24px;
  object-fit: cover;
  opacity: 0.3;
  transition: 1s ease;
  cursor: pointer;
}

.caringImg4:hover {
  opacity: 0;
}
.img4Box {
  position: relative;
  cursor: pointer;
}
.viewGalleryTxt {
  display: flex;
  position: absolute;
  color: #fff;
  text-align: right;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 132%; /* 26.4px */
  text-transform: capitalize;
  top: 50%;
  width: 100%;
  justify-content: center;
  gap: 24px;
}

.galleryBtnClose {
  position: absolute;
  z-index: 1;
  right: 32px;
  top: 150px;
  cursor: pointer;
}

.hospitalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #0e0320;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 106px;
}
.hospitalHeading {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 150% */
  margin-bottom: 16px;
}
.hospitalDescription {
  width: 1279px;
  color: #efedfd;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 150% */
}
.cardHospitalsContainer {
  display: flex;
  gap: 24px;
  margin-top: 48px;
  margin-bottom: 120px;
}
.hospitalCard {
  position: relative;
  width: 302px;
  height: 187.548px;
}
.hospitalTitle {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.312px; /* 108.201% */
  position: absolute;
  bottom: 15.57px;
  left: 12px;
}

.hospitalCardImg {
  width: 302px;
  height: 187.548px;
  flex-shrink: 0;
  border-radius: 11.541px;
  opacity: 0.8;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 40%,
    rgba(34, 33, 35, 0.15) 50.31%,
    rgba(25, 25, 26, 0.49) 62.36%,
    #232124 100%
  );
}

.mealScheduleTitle {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 150% */
  margin-bottom: 16px;
}

.mealDescription {
  width: 1280px;
  max-width: 1280px;
  color: #efedfd;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 150% */
}

// ======================================================================media==================================================

@media (max-width: 992px) {
  .csrBannerContainer {
    height: 671px;
  }

  .csrBannerChildContainer {
    height: 777px;
  }

  .csrBannerHeading {
    width: 343px;
    color: #e3e2ff;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    letter-spacing: -0.64px;
  }

  .csrBannerDescription {
    width: 339px;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    letter-spacing: -0.32px;
  }

  .carouselCsrBannerImgContainer {
    width: 100%;
    height: 228.452px;
    height: 228.452px;
  }

  .carouselCsrBannerImg {
    width: 100vw;
    padding: 1rem;

    object-fit: cover;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 100%) !important;
  }

  .csrBannerEle {
    gap: 16px;
    height: auto;
  }
  .carouselContainerCsr {
    height: 280px;
  }
  .makingDiffImg {
    width: 343px;
    height: 284px;
  }
  .makingMeaningfulDiffContainer {
    padding-top: 0px;
    padding: 0px 16px;
    padding-bottom: 24px;
  }
  .makingMeaningfulDiffBox {
    width: 343px;
    flex-direction: column;
    gap: 32px;
    flex-direction: column-reverse;
  }
  .rightSectionMakingMeaningfulDiffBox {
    width: 100%;
  }
  .makingTxtHeading {
    width: 100%;
    align-self: stretch;
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 35.573px; /* 148.221% */
  }
  .makingTxtDescription {
    width: 100%;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    align-self: stretch;
  }

  .caringContainer {
    padding-top: 24px;
    padding-bottom: 30.51px;
  }
  .caringImgBox {
    margin-bottom: 32px;
  }

  .caringImg1 {
    display: none;
  }
  .caringImg2 {
    width: 343px;
    height: 156.753px;
  }
  .caringImg3 {
    width: 164.946px;
    height: 156.753px;
  }
  .caringImg4 {
    width: 164.946px;
    height: 156.753px;
  }

  .caringHeading {
    width: 323px;
    text-align: left;
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 35.573px; /* 148.221% */
    margin-bottom: 12px;
  }

  .caringSubHeading {
    width: 323px;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: left;
    margin-bottom: 32px;
  }

  .viewGalleryTxt {
    color: #fff;
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    text-transform: capitalize;
    gap: 6px;
  }
  .flexRowCaringImg {
    gap: 13.11px;
  }
  .leftImgStrip {
    gap: 8.74px;
  }

  .hospitalHeading {
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 35.573px; /* 148.221% */
    margin-bottom: 12px;
  }
  .hospitalDescription {
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 343px;
    text-align: left;
  }
  .cardHospitalsContainer {
    overflow: hidden;
    width: 100%;
    overflow-x: scroll;
    margin-top: 16px;
    margin-bottom: 48.45px;
  }
  .cardHospitalsContainer::-webkit-scrollbar {
    display: none;
  }
  .hospitalCardImg {
    overflow: hidden;
  }
  .hospitalContainer {
    padding-top: 0;
    padding-bottom: 48px;
  }
  .mealScheduleTitle {
    width: 343px;
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 35.573px; /* 148.221% */
  }
  .mealDescription {
    width: 343px;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: left;
  }
}
