.customersBannerContainer {
  height: 786px;
  width: 100%;
  background: #0e0320;
  position: relative;
}
.customersBannerChildContainer {
  position: absolute;
  height: 892px;
  width: 100%;
  top: -106px;
}

.featuredCaseStudiesContainer {
  padding: 120px 165px;

  display: flex;
  flex-direction: column;
  background: #0e0320;
  justify-content: center;
  align-items: center;
}
.featuredCaseStudyTitle {
  color: #ff988a;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140.5%; /* 33.72px */
  letter-spacing: -0.48px;
  margin-bottom: 12px;
}
.featuredCaseStudyHeading {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%; /* 59.01px */
  letter-spacing: -0.84px;
  margin-bottom: 48px;
}

.caseStudyFeaturedCardConatiner {
  display: flex;
  gap: 156px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.caseStudyFeaturedCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.caseStudyFeaturedImg {
  position: relative;
}
.caseStudyFeaturedImgCard {
  width: 266px;
  height: 266px;
  border-radius: 266px;
  margin-bottom: 18px;
  object-fit: cover;
}
.caseStudyFeaturedCardTitle {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  margin-bottom: 20px;
}

.caseStudyFeaturedCardDescription {
  width: 261px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 20px;

  line-height: normal;
}

.caseStudyFeaturedCardButton {
  color: #9781ee;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 132%; /* 26.4px */
  text-transform: capitalize;
}
.caseStudyFeaturedCardButton:hover {
  img {
    transform: rotate(45deg);
    transition: 0.3s;
  }
}
.caseStudyFeaturedImgContainer {
  position: relative;
}

.stickerCardContainerImg {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 86.1px;
  height: 86.1px;
  background-color: white;
  border-radius: 50%;
}

.stickerCardImg {
  position: absolute;
  left: 4.05px;
  top: 26.971px;
  width: 76.95px;
  height: 32.158px;
}

.caseStudiesCustomersContainer {
  display: flex;
  flex-direction: column;
  background: #0e0320;
  align-items: center;
}
.filtertabCaseStudy {
  display: flex;
  padding: 6px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 183.5%; /* 33.03px */
  letter-spacing: -0.36px;
  margin-bottom: 48px;
}

.filterCaseStudyBtn {
  display: flex;
  padding: 6px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 33px;
  border: 1px solid #3c2a74;
  background: rgba(102, 91, 216, 0.2);
  cursor: pointer;
}

.filterCaseStudyBtn.selected {
  background: #665bd8;
}

.caseStudiesCardContainer {
  display: flex;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 48px;
  justify-content: flex-start;
  padding-left: 80px;
}
.caseStudyCustomersCard {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.caseStudyCustomersCardTitle {
  color: #9781ee;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
  text-transform: capitalize;
  margin-bottom: 16px;
}
.caseStudyCustomersCardDescription {
  width: 384px;
  flex-shrink: 0;
  color: #eff2ef;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
}

.caseStudiesCustomersCardImgContainer {
  width: 411px;
  height: 302px;
  flex-shrink: 0;
  border-radius: 20px;
  margin-bottom: 32px;
}
.caseStudiesCustomersCardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 32px;
}

.loadMoreBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 21.06px;
  width: 212px;
  height: 60px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 132%; /* 26.4px */
  text-transform: capitalize;
  cursor: pointer;
  padding: 1rem;
  background-color: #665bd8;
  border-radius: 40px;
  margin-bottom: 120px;
  transition: 0.3s;
}

.loadMoreBtn:hover {
  img {
    transform: rotate(45deg);
    transition: 0.3s;
  }
  background-color: #ec4d37;
}

.customersVideoBanner {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.customersBannerElem {
  // position: absolute;
  position: relative;
  left: 0px;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 46%;
}
.customersBannerHeading {
  margin-top: 80px;
  width: 767px;
  color: #e3e2ff;
  text-align: center;
  font-family: Poppins;
  font-size: 62px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 116.129% */
}

.bannerSubHeadingTxt {
  background: var(
    --Ngenux-gradient,
    linear-gradient(97deg, #fd95ff 2.52%, #9747ff 104.52%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Poppins;
  font-size: 62px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
}
.customersBannerSubHeading {
  color: #efedfd;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.customersBannerFooter {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.customersBannerLogos {
  display: flex;
  gap: 100px;
}

.customersBannerLogos {
  img {
    height: 48.185px;
  }
}

// ===============================================================media====================================================
@media (min-width: 993px) and (max-width: 1366px) {
  .caseStudyFeaturedCardConatiner {
    gap: 100px;
  }
  .customersBannerLogos {
    gap: 50px;
  }
  .customersBannerHeading {
    font-size: 55px;
  }
  .caseStudiesCardContainer {
    padding-left: 0;
  }
}
@media (max-width: 992px) {
  .customersBannerContainer {
    height: 681px;
  }
  .customersBannerChildContainer {
    top: -64px;
    height: 531px;
  }

  .caseStudyFeaturedImgCard {
    width: 200px;
    height: 200px;
  }
  .stickerCardContainerImg {
    width: 64.737px;
    height: 64.737px;
  }

  .stickerCardImg {
    width: 57.857px;
    height: 24.179px;
    position: absolute;
    left: 3.046px;
    top: 20.279px;
  }

  .featuredCaseStudiesContainer {
    padding: 0;
    padding-top: 24px;
    padding-bottom: 60px;
  }

  .featuredCaseStudyTitle {
    color: #ff988a;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.5%; /* 22.48px */
    letter-spacing: -0.32px;
  }

  .featuredCaseStudyHeading {
    width: 343px;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
  }

  .caseStudyFeaturedCardConatiner {
    width: 100%;
    gap: 24px;
    overflow: hidden;
    overflow-x: scroll;
    justify-content: flex-start;
    padding-left: 32px;
    flex-wrap: nowrap;
  }
  .customersVideoBanner {
    object-fit: fill;
  }
  .caseStudyFeaturedCardTitle {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    margin-bottom: 12px;
  }
  .caseStudyFeaturedCardDescription {
    width: 261px;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
  }
  .caseStudyFeaturedCardButton {
    color: #9781ee;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    text-transform: capitalize;
  }

  .filterCaseStudyBtn {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 183.5%; /* 25.69px */
    letter-spacing: -0.28px;
    text-wrap: nowrap;
  }
  .filtertabCaseStudy {
    width: 100%;
    overflow-x: scroll;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .caseStudiesCardContainer {
    align-items: baseline;
    flex-direction: column;
    width: 343px;
    padding: 0px;
    margin-bottom: 24px;
  }
  .caseStudiesCustomersCardImgContainer {
    width: 343px;
    height: 251.051px;
    flex-shrink: 0;
    border-radius: 20px;
    margin-bottom: 16px;
  }

  .caseStudiesCustomersCardImg {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    object-fit: cover;
  }
  .caseStudyCustomersCard {
    width: 312px;
  }
  .caseStudyCustomersCardTitle {
    margin-bottom: 8px;
    color: #9781ee;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    text-transform: capitalize;
  }
  .caseStudyCustomersCardDescription {
    width: 312px;
    color: #eff2ef;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
  }

  .caseStudiesCustomersContainer {
    padding: 0;
    padding-bottom: 24px;
  }

  .customersBannerHeading {
    color: #e3e2ff;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    width: 343px;
  }
  .customersBannerSubHeading {
    color: #efedfd;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .customersBannerFooter {
    gap: 40px;
    width: 343px;
  }
  .bannerSubHeadingTxt {
    background: var(
      --Ngenux-gradient,
      linear-gradient(97deg, #fd95ff 2.52%, #9747ff 104.52%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }
  .customersBannerLogos {
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    img {
      flex-shrink: 0;
    }
  }

  .customersBannerHeading {
    margin-top: 40px;
  }
  .customersBannerElem {
    gap: 34%;
  }
}
