.solutionsDetailsContainer {
  height: 670px;
  position: relative;
  top: -106px;
}
.solutionsDetailSubContainer {
  background-image: url("../assets/solutionsDetailsPage/bannerBackground.png");
  background-position: bottom;
  background-size: cover;
  height: 776px;
}

.arrowNavigatorTab {
  display: flex;
  gap: 15px;
}
.rotateArrow {
  cursor: pointer;
  transform: rotate(180deg);
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke-width: 10px !important;

  opacity: 0.12 !important;
}
.CircularProgressbar .CircularProgressbar-text {
  text-align: center !important;
  font-family: Poppins !important;
  font-style: normal;
  font-weight: 600;
  line-height: 64px; /* 100% */
  letter-spacing: -1.28px;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke-width: 10px !important;
  stroke: #d1aefe !important;
}

.CircularProgressbar {
  overflow: visible;
}

.bannerSolutionsDetail {
  display: flex;
  position: absolute;
  top: 80px;
  padding-top: 120px;
  padding-left: 7%;
  padding-right: 7%;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 133px;
}

.bannerSolutionsDetailEle {
  display: flex;
  flex-direction: column;
  gap: 37px;
}

.headingDetailsSol {
  color: #e3e2ff;
  font-family: Poppins;
  font-size: 62px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 116.129% */
}

.subHeadingDetailsSol {
  color: #efedfd;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btnBoxSolutionsDetails {
  display: flex;
  gap: 24px;
}

.detailsSolutionBannerImg {
  width: 519px;
  height: 430px;
  flex-shrink: 0;
}

.solutionMetricesContainer {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #05010f 0%, #0e0320 100%);
  padding: 80px 0px 120px 0px;
  align-items: center;
}

.solutionsMetricesCardContainer {
  display: flex;
  gap: 24px;
}

.solutionsMetricesCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  background: linear-gradient(
    185deg,
    rgba(142, 97, 189, 0.2) -26.06%,
    rgba(97, 73, 164, 0.2) 121.46%
  );
  width: 411px;
  height: 247px;
  gap: 36px;
  justify-content: center;
  align-items: center;
}

.solutionMetricesHeading {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px; /* 123.81% */
  letter-spacing: -0.84px;
  margin-bottom: 48px;
}

.solutionMetricesSubheading {
  color: #efedfd;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 1051px;
  margin-bottom: 64px;
}

.chartContentContainer {
  background: #0e0320;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 0px;
  padding-top: 24px;
}
.chartTitle {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px; /* 123.81% */
  letter-spacing: -0.84px;
  margin-bottom: 40px;
}

.chartDescription {
  color: #efedfd;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 1062px;
}
.chartImgContainer {
  width: 1279px;
  height: auto;
  margin-top: 80px;
  margin-bottom: 120px;
}
.chartImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.chartContentTitle {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px; /* 123.81% */
  letter-spacing: -0.84px;
  margin-bottom: 48px;
}
.chartContentDescription {
  color: #efedfd;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 120px;
  max-width: 1280px;
}

.solutionsMetricesCardText {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 107.143% */
  letter-spacing: -0.56px;
}

.solutionsCaseStudyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #0e0320;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 120px;
}

.solutionsCaseStudyHeading {
  color: #ff988a;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140.5%; /* 33.72px */
  letter-spacing: -0.48px;
  margin-bottom: 12px;
}

.solutionsCaseStudySubHeading {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 140.5%; /* 59.01px */
  letter-spacing: -0.84px;
  width: 1280px;
  margin-bottom: 48px;
}

.solutionsCaseStudyCard {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 133px;
  width: 100%;
}

.solutionsCaseStudyCardImg {
  width: 628px;
  height: 520px;
  flex-shrink: 0;
  margin-bottom: 48px;
  border-radius: 25px;
  object-fit: cover;
}

.solutionsCaseStudyCardLeftSection {
  display: flex;
  flex-direction: column;
}

.solutionsCaseStudyCardTitle {
  color: #fff;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 125% */
  width: 579px;
  margin-bottom: 21px;
}

.solutionsCaseStudyCardDescription {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 519px;
  margin-bottom: 32px;
}

.visulapresentationContainer {
  background: #0e0320;
  display: flex;
  flex-direction: column;
  padding-bottom: 96px;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  gap: 342px;
}

.loaderDescription {
  color: #efedfd;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 302px;
  margin-top: 46px;
}
.loaderHeader {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px; /* 123.81% */
  letter-spacing: -0.84px;
  margin-bottom: 96px;
}

// ------------------------------------------------------------------media---------------------------------------------------
@media (min-width: 993px) and (max-width: 1366px) {
  .solutionsCaseStudyCard {
    gap: 30px;
  }
  .solutionsCaseStudyCardImg,
  .detailsSolutionBannerImg {
    width: 450px;
    height: 445px;
    object-fit: cover;
    border-radius: 25px;
  }
  .loaderContainer {
    gap: 200px;
  }
  .solutionsMetricesCard {
    width: 300px;
    padding: 0.5rem;
    justify-content: flex-start;
    padding-top: 2rem;
  }
  .solutionsCaseStudySubHeading,
  .solutionsCaseStudyCardTitle,
  .chartDescription,
  .chartImgContainer {
    width: auto;
  }
  .headingDetailsSol {
    font-size: 55px;
  }
  .solutionMetricesContainer {
    padding: 80px 30px;
  }
  .solutionMetricesHeading,
  .chartContentTitle {
    margin-bottom: 40px;
  }
  .chartContentContainer {
    padding: 0 30px;
  }
  .solutionsCaseStudyCardDescription {
    width: auto;
    max-width: 519px;
  }
  .solutionsCaseStudyContainer {
    padding: 0 30px;
  }
  .bannerSolutionsDetail {
    gap: 80px;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
  }
  .solutionsMetricesCardText {
    line-height: 40px;
  }
}
@media (max-width: 992px) {
  .solutionsDetailsContainer {
    top: -63px;
  }
  .CircularProgressbar {
    width: 151px;
    height: 151px;
  }
  .bannerSolutionsDetail {
    flex-direction: column;
    flex-direction: column-reverse;
    height: auto;
    padding: 1rem;
    padding-top: 0px;
    gap: 24px;
    top: 94px;
  }

  .bannerSolutionsDetailEle {
    gap: 24px;
  }

  .detailsSolutionBannerImg {
    width: 343px;
    height: 284.181px;
  }
  .headingDetailsSol {
    color: #e3e2ff;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    letter-spacing: -0.64px;
    width: 100%;
  }
  .subHeadingDetailsSol {
    color: #efedfd;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.32px;
  }
  .btnBoxSolutionsDetails {
    flex-direction: column;
    align-items: center;
  }

  .solutionMetricesContainer {
    padding: 48px 16px 24px 16px;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .solutionMetricesHeading {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
    margin-bottom: 16px;
  }
  .solutionMetricesSubheading {
    color: #efedfd;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 32px;
  }

  .solutionsMetricesCard {
    width: 343px;
  }
  .solutionsMetricesCardContainer {
    flex-direction: column;
    gap: 12px;
  }
  .solutionsMetricesCardText {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
    letter-spacing: -0.4px;
  }

  .solutionsCardImg {
    width: 56px;
    height: 56px;
  }

  .solutionsDetailSubContainer {
    height: 734px;
  }
  .chartImgContainer {
    width: 343px;
    padding: 0;
    margin-top: 40px;
    margin-bottom: 24px;
    height: auto;
  }

  .chartImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .chartContentDescription {
    width: 343px;
    color: #efedfd;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 40px;
  }
  .chartDescription {
    color: #efedfd;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 343px;
  }
  .chartContentTitle {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
    width: 343px;
    margin-bottom: 16px;
  }

  .chartTitle {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
    width: 343px;
    margin-bottom: 16px;
  }
  .chartContentContainer {
    padding: 1rem;
    padding-bottom: 0px;
  }
  .solutionsCaseStudyHeading {
    color: #ff988a;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.5%; /* 22.48px */
    letter-spacing: -0.32px;
    margin-bottom: 8px;
  }
  .solutionsCaseStudySubHeading {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
    width: 343px;
    margin-bottom: 32px;
  }
  .solutionsCaseStudyCard {
    gap: 0px;
    flex-direction: column;
    width: 343px;
  }
  .solutionsCaseStudyCardImg {
    width: 343px;
    height: 284.013px;
    margin-top: 0px;
    margin-bottom: 14px;
  }
  .solutionsCaseStudyContainer {
    padding: 24px 16px 48px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .solutionsCaseStudyCardLeftSection {
    width: 100%;
    align-items: center;
  }
  .solutionsCaseStudyCardDescription {
    width: 343px;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 24px;
  }
  .solutionsCaseStudyCardTitle {
    width: 343px;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin-bottom: 8px;
  }
  .caseStudyBtn {
    margin-bottom: 32px;
  }

  .visulapresentationContainer {
    align-items: center;
    padding-bottom: 48px;
  }

  .loaderHeader {
    width: 343px;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
    margin-bottom: 40px;
  }

  .loaderDescription {
    width: 311px;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
  }

  .loaderContainer {
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
}
