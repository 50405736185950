.jobDetailsContainer {
  padding: 0 10%;

  .jobDetailImg {
    background-repeat: no-repeat;
    width: 100%;
    position: absolute;
    z-index: -10;
    top: 0;
    left: 0;
  }
  .jobDetailsHeader {
    display: flex;
    padding: 2rem 0rem;
    flex-direction: column;
    align-items: self-start;
    gap: 3rem;
    margin: 0 10%;
  }
  .jobDetailsHeadContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    .jobDetailsTitle {
      > p {
        margin: 0;
        color: #e3e2ff;
        font-family: Poppins;
        font-size: 3.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 4.5rem; /* 116.129% */
      }
    }
    .jobDetailsTags {
      color: #fff;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem; /* 150% */
      letter-spacing: -0.005rem;
    }
    .jobDetailsPills {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      gap: 1rem;
      flex-wrap: wrap;
      > a {
        display: flex;
        height: 2.25rem;
        padding: 0.25rem 0.80081rem 0.33694rem 0.8125rem;
        justify-content: center;
        align-items: center;
        color: #8f4d7c;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.9625rem; /* 96.25% */
        border-radius: 1.875rem;
        opacity: 0.94;
        background: #fff;
        text-decoration: none;
      }
    }
  }

  .jobDetailsContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    margin: 0 10%;
    .jobDetailsmarkdown * {
      color: white;
    }
    .jobApplyForm {
      background: #1f0f39;
      height: auto;
      width: 100%;
      padding: 3% 2%;
      margin-bottom: 5%;
      fill: #1f0f39;
      stroke-width: 1px;
      stroke: #fd95ff;
      border-radius: 3%;
      .jobForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        width: 100%;
        .jobFormInputsContainer {
          width: 100%;
          > p {
            color: #e3e2ff;
            font-family: Poppins;
            font-size: 1.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: 2rem; /* 114.286% */
            text-align: center;
          }
          > form {
            > .row {
              --bs-gutter-x: 0;
              width: 100%;
              > .col-md-6 {
                text-align: center;
                padding: 0;
              }
              > .customfile-col {
                margin-top: 1%;
              }
            }
            .jobFormInputs {
              width: 95%;
              height: 3.5rem;
              flex-shrink: 0;
              border-radius: 0.5rem;
              border: 1px solid rgba(255, 255, 255, 0.16);
              opacity: 0.6;
              background: #0e0320;
              color: #ffffff;
              text-indent: 2%;
              margin: 2%;
              .PhoneInputInput {
                background: inherit;
                border: none;
                color: #fff;
              }
              .PhoneInputCountry {
                padding-left: 2%;
              }
            }
            .jobFormInputs::placeholder {
              // color: rgba(255, 255, 255, 0.60);
              font-family: Poppins;
              font-size: 1.125rem;
              font-style: normal;
              font-weight: 400;
              line-height: 163.5%; /* 1.83938rem */
            }
            .upload-btn-wrapper {
              position: relative;
              overflow: hidden;
              display: inline-block;
              width: 95%;
              border-radius: 0.5rem;
              border: 1px solid rgba(255, 255, 255, 0.16);
              background: #0e0320;
              opacity: 75%;
              cursor: pointer;
            }

            .upload-btn-wrapper-btn {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: 0;
              color: #ffffff;
              background: #0e0320;
              padding: 8px 10px;
              border-radius: 8px;
              cursor: pointer;
              width: 100%;
              height: 3.5rem;
              color: rgba(255, 255, 255, 0.6);
              font-family: Poppins;
              font-size: 1rem;
              font-style: normal;
              font-weight: 400;
              line-height: 163.5%; /* 1.83938rem */
              opacity: 75%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .upload-btn-wrapper input[type="file"] {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }

            .upload-file-selected {
              color: white;
            }
            .formErrorContainer {
              .formError {
                color: #ff6363;
                font-family: Poppins;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1rem; /* 114.286% */
                margin: 0;
                padding: 1% 0 0 2%;
              }
            }
          }
        }
        .jobFormSubmit {
          .interactiveBtn {
            display: flex;
            padding: 1rem 2rem;
            align-items: center;
            gap: 1rem;
            width: 12rem;
            height: 3.5rem;
            justify-content: center;
          }
        }
      }

      .jobApplypopUpContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        margin: 10%;
        .popUpMessage {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;
          > p {
            margin: 0;
          }
          > p:nth-child(1) {
            color: #e3e2ff;
            text-align: center;
            font-family: Poppins;
            font-size: 1.75rem;
            font-style: normal;
            font-weight: 500;
            line-height: 2rem; /* 114.286% */
          }
          > p:nth-child(2) {
            color: #e3e2ff;
            text-align: center;
            font-family: Poppins;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 132%; /* 1.65rem */
          }
        }
      }
    }
  }
}
// -------------------------------------------mediaQurrey----------------------------------------------------------------

@media (max-width: 1385px) {
  .jobDetailsContainer {
    padding: 2% 5%;
    .jobDetailsContent .jobApplyForm {
      padding: 5% 5% 6% 5%;
    }
    .jobDetailsHeader {
      padding: 0;
      margin: 0;
      .jobDetailsHeadContent {
        gap: 1rem;
        .jobDetailsTitle {
          > p {
            font-size: 2rem;
            line-height: normal;
          }
        }
        .jobDetailsPills {
          gap: 0.5rem;
          > a {
            font-size: 0.8rem;
            height: 2rem;
            padding: 0.5rem;
          }
        }
      }
    }

    .jobDetailsContent {
      margin: 5% 0;
      .jobApplyForm .jobForm .jobFormInputsContainer > form > .row {
        width: auto;
      }
    }
  }
  .jobApplypopUpContainer > .popUpMessage {
    > p:nth-child(1) {
      text-align: center;
      font-size: 1.5rem !important;
      font-style: normal;
      font-weight: 500;
      line-height: 2.5rem;
    }
    > p:nth-child(2) {
      font-size: 1rem !important;
      font-style: normal;
      font-weight: 400;
      line-height: 132%; /* 1.32rem */
    }
  }
  .jobFormInputsContainer > form .jobFormInputs {
    text-indent: 5% !important;
    .PhoneInputCountry {
      padding-left: 5% !important;
    }
  }
}
