.insightsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  background: linear-gradient(
    180deg,
    rgba(96, 50, 117, 0) 0%,
    rgba(96, 50, 117, 0.31) 28.9%,
    rgba(178, 61, 35, 0) 62.19%
  );
  background-color: #0c0225;
  padding-top: 60px;
  padding-bottom: 60px;
  padding-left: 80px;
  padding-right: 80px;
  gap: 42px;
}

.insightsHeading {
  color: #ff988a;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 140.5%; /* 33.72px */
  letter-spacing: -0.48px;
}

.greatIdeasTextBox {
  max-width: 818px;
  text-align: center;
  p {
    margin-bottom: 0rem;
  }
}

.greatIdeasText {
  color: #eff2ef;
  text-align: center;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px; /* 128.571% */
  letter-spacing: -0.84px;
}

.seewhatText {
  color: #eff2ef;
  font-family: Poppins;
  font-size: 42px;
  font-style: normal;
  font-weight: 300;
  line-height: 54px;
  letter-spacing: -0.84px;
}

.cardInsightsContainer {
  display: flex;
  gap: 24px;
  margin-bottom: 6px;
}

.cardInsights {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.imgBox {
  width: 411px;
  height: 302px;
  overflow: hidden;
  border-top-right-radius: 40px;

  position: relative;
  img {
    border-radius: 8px 40px 8px 8px;
    width: 100%;
    height: 100%;
    object-fit: fill;
    transition: transform 1.2s;
  }
}

.imgBox img:hover {
  transform: scale(1.1);
}

.insightsTextCard {
  color: #eff2ef;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 373px;
  margin-top: 32px;
}

.insightsBtnCard {
  cursor: pointer;
  display: flex;
  width: 86px;
  height: 24px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: rgba(151, 129, 238, 1);
  text-align: left;
  margin-top: 20px;
}

.insightsPillCard {
  position: absolute;
  top: 12px;
  left: 12px;
  color: #8f4d7c;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.4px; /* 110% */
  border-radius: 12px;
  opacity: 0.94;
  background: #fff;
  padding: 4px 12.813px 5.391px 13px;
}

// -------------------------------------------mediaQurrey----------------------------------------------------------------

@media (max-width: 992px) {
  .cardInsightsContainer {
    flex-direction: column;
    width: 343px !important;
    margin-bottom: 16px;
  }

  .imgBox {
    width: 100%;
    height: 243px;
    img {
      width: 100%;
    }
  }

  .insightsContainer {
    padding: 1rem;
    gap: 8px;
  }

  .insightsHeading {
    color: #ff988a;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140.5%; /* 22.48px */
    letter-spacing: -0.32px;
  }
  .greatIdeasText {
    width: 100%;
    color: #eff2ef;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
  }
  .seewhatText {
    color: #eff2ef;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: -0.4px;
  }
  .insightsTextCard {
    width: 100%;
    color: #eff2ef;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media (min-width: 993px) and (max-width: 1366px) {
  .insightsCatCard {
    width: 18rem;
  }

  .imgBox {
    width: 100%;
    height: 15rem;
  }

  .fedInsightsPillCard {
    top: 13px;
  }
  .insightsContainer {
    gap: 12px;
    padding-top: 80px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .cardInsightsContainer {
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 30px;
  }
  .imgBox img {
    object-fit: cover;
  }
}
