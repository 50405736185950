.impactContainer {
  width: 411px;
  height: 362px;
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background: linear-gradient(
    185deg,
    rgba(142, 97, 189, 0.2) -26.06%,
    rgba(97, 73, 164, 0.2) 121.46%
  );
  filter: blur(0px);
  margin: 0px 20px;
}

.imgContainer {
  padding: 20px;
}

.impactContainerTitle {
  font-weight: 600;
  font-size: 28px;
  padding: 20px 0;
}

.impactContaierDes {
  font-size: 20px;
  font-weight: 200;
  text-align: center;
}
@media (min-width: 993px) and (max-width: 1366px) {
  .impactContaierDes {
    font-size: 18px;
  }
  .impactContainer {
    margin: 0;
    padding-top: 5%;
    justify-content: flex-start;
  }
}
@media (max-width: 992px) {
  .impactContainer {
    margin: 20px 20px;
  }

  .impactContainer {
    width: 343px;
    height: 236px;
    font-size: 16px;
  }

  .impactContaierDes {
    font-size: 16px;
  }

  .impactContainerTitle {
    font-size: 20px;
    padding: 15px 0;
  }
}
