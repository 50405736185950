#cards {
  // position: sticky;
  padding-bottom: calc(
    var(--numcards) * var(--card-top-offset)
  ); /* Make place at bottom, as items will slide to that position */
  margin-bottom: var(
    --card-margin
  ); /* Don't include the --card-margin in padding, as that will affect the scroll-timeline */
}

#card_1 {
  --index: 1;
}
#card_2 {
  --index: 2;
}
#card_3 {
  --index: 3;
}
#card_4 {
  --index: 4;
}

.card {
  padding-top: calc(var(--index) * var(--card-top-offset));
  outline: var(--outline-width) solid hotpink;
}
.stickyText {
  position: sticky;
  top: 156px;
  height: 250px;
}

.whatweBringCard {
  position: sticky;
  top: 406px;
  padding-top: calc(var(--index) * var(--card-top-offset));
  background: rgba(225, 197, 255, 1);
  border-radius: 30px;
  padding-top: 42px;
  padding-bottom: 42px;
  padding-left: 48px;
  width: 600px;
  height: 226px;
  margin-bottom: 36px;
}

.whatweBringCardTitle {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.whatweBringCardText {
  color: #0b0b0b;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%; /* 22.4px */
  width: 305px;
}

.whatweBringCardImg {
  position: absolute;
  top: 0px;
  right: 0px;
}

@supports (scroll-timeline: auto) {
  @scroll-timeline cards-element-scrolls-in-body {
    source: none;
    scroll-offsets: from 10vh, to bottom;
  }

  .card__content {
    transform-origin: 50% 0%;
    will-change: transform;
    --index0: calc(var(--index) - 1); /* 0-based index */
    --reverse-index: calc(var(--numcards) - var(--index0)); /* reverse index */
    --reverse-index0: calc(
      var(--reverse-index) - 1
    ); /* 0-based reverse index */
    --duration: calc(var(--reverse-index0) * 1s);
    --delay: calc(var(--index0) * 1s);
    animation: scale var(--duration) linear var(--delay) forwards;
    animation-timeline: cards-element-scrolls-in-body;
  }

  @keyframes scale {
    to {
      transform: scale(calc(1.1 - (0.1 * var(--reverse-index))));
    }
  }
}

/* PAGE STYLING */
* {
  /* Poor Man's Reset */
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

//media query

@media (max-width: 992px) {
  .whatweBringCardContainer {
    align-items: center;
    height: auto;
  }

  .whatweBringCard {
    padding: 0px;
    border-radius: 12px;
    width: 343px;
    height: 160px;
    padding-left: 16px;
    padding-bottom: 32px;
    padding-top: 32px;
    top: 65px;
  }
  .whatweBringCardTitle {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 100% */
    margin-bottom: 8px;
  }
  .whatweBringCardText {
    color: #0b0b0b;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 16.8px */
    width: 200px;
    height: auto;
  }
  .whatweBringCardImg {
    position: absolute;
    top: auto;
    right: 0px;
    height: 128px;
    width: 128px;
    bottom: 0px;
  }

  .stickyText {
    position: static;
    height: auto;
  }
}

@media (min-width: 993px) and (max-width: 1366px) {
  .whatwebringContainer {
    width: 100%;
    height: 1480px;
  }

  .stickyText {
    position: sticky;
    top: 156px;
    height: 250px;
  }

  .whatweBringCard {
    position: sticky;
    top: 320px;
    padding-top: calc(var(--index) * var(--card-top-offset));
    background: rgba(225, 197, 255, 1);
    border-radius: 30px;
    padding-top: 42px;
    padding-bottom: 42px;
    padding-left: 48px;
    width: 600px;
    height: 226px;
    margin-bottom: 36px;
  }

  .whatweBringCardTitle {
    color: #000;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .whatweBringCardText {
    color: #0b0b0b;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 22.4px */
    width: 305px;
  }

  .whatweBringCardImg {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    height: 170px;
    width: auto;
  }
}
