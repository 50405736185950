.headerContainer {
  display: none;
}
.hamBurger {
  display: none;
}
.ngenuxLogo {
  display: none;
}
.ngenuxLogoMenu {
  display: none;
}
.menuContainer {
  display: none;
}
.headerDeskContainer {
  background-color: transparent;
  width: 100%;
  display: flex;
  display: flex;
  height: 106px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  position: sticky;
  top: 0px;
  z-index: 3;
}
.scrClass {
  z-index: 1;
}
.flexSolutionsTab {
  position: relative;
  display: flex;
  align-items: center;
}
.flexSolutionsTab:hover img {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.headerEleContainer {
  z-index: 1;
  padding: 9px 80px 21px 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.arrowContainer {
  height: 100%;
  line-height: 100%;
  opacity: 50%;
}
.arrowContainer:hover {
  opacity: 100%;
}

.hoverContainerBox {
  display: none;
  position: absolute;
  width: 330px;
  height: 240px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.5);
  padding: 24px 32px;
  backdrop-filter: blur(20px);
  left: -15.4%;
  top: 90px;
  height: auto;
}
.hoverContainerElemets {
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #fff;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.flexSolutionsTab p {
  margin-block-end: 2em;
  margin-block-start: 2em;
}

.flexSolutionsTab:hover .hoverContainerBox {
  display: block;
}

.lightHeaderText {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
}

.boldHeaderText {
  color: #fff;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

// ------------------------------------------------------------------MEDIA-QURREYS----------------------------------------------

@media (min-width: 993px) and (max-width: 1366px) {
  .headerEleContainer {
    z-index: 1;
    // padding: 9px 80px 21px 80px;
    padding: 5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  .hoverContainerElemets {
    font-size: 20px;
  }
  .lightHeaderText {
    font-size: 16px;
  }
}

@media (max-width: 992px) {
  .headerContainer {
    background-color: transparent;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
    position: sticky;
    top: 0px;

    padding-top: 20px;
    padding-bottom: 20px;
  }
  .body-no-scroll {
    overflow: hidden;
  }
  .scrolled {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(12px);
    z-index: 2;
  }
  .ngenuxLogo {
    display: block;
    width: 86.531px;
    height: 24px;
    margin-left: 16px;
  }
  .ngenuxLogoMenu {
    display: block;
    margin-left: 16px;
  }
  .menuContainer {
    display: block;
    position: absolute;
    top: 0%;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    width: 100%;
    height: 100vh;
  }

  .mobileViewClass {
    position: fixed;
    height: auto;
    z-index: 2;

    top: 0;
    width: 100%;
    right: -100%;
    transition: right 0.5s ease !important;
  }

  .mobileViewClass.open {
    right: 0;
  }
  .optionsContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-left: 24px;
  }
  .solutionBox {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .solutionNavigate {
    display: flex;
    align-items: center;
  }
  .solutionContainerClicked {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .menuHeader {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: transparent;
  }
  .hamBurger {
    display: block;
    margin-right: 16px;
  }
  .transformedArrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease; /* Add a smooth transition for animation */
  }
  .headerDeskContainer {
    display: none;
  }
}
