.journeyContainer {
  position: relative;
  background: #0c0225;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 40px 0;
  padding-bottom: 120px;
}

.cardParent {
  display: flex;
  justify-content: center;
}

.cardContainer {
  background: url("../assets/homepage/journeyCardBg.svg");
  background-size: cover;
  width: 1231px;
  height: 450px;
  border-radius: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.traingleJourneycard {
  position: absolute;
}
.journeyText {
  margin-top: 86px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 140.5%; /* 75.87px */
  letter-spacing: -1.08px;
}

.createthebestText {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 593px;
}
.footerBtn {
  margin-top: 42px;
}

//---------------------------------------------media-qurrey--------------------------------------------------------------

@media (max-width: 992px) {
  .cardParent {
    width: 100%;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .journeyContainer {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .cardContainer {
    width: 343px;
    height: 228px;
    justify-content: center;
    background: url("../assets/Journey.svg");
  }
  .journeyText {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.5%; /* 28.1px */
    letter-spacing: -0.4px;
    margin-top: 0px;
  }
  .createthebestText {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 238px;
  }
  .footerBtn {
    margin: 0px;
  }
}

@media (min-width: 993px) and (max-width: 1366px) {
  .cardParent {
    width: -webkit-fill-available;
    padding: 0 30px;
    height: 300px;
  }
  .cardContainer {
    width: 100%;
    height: 300px;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2rem 0;
  }

  .journeyText {
    margin-top: 0;
    font-size: 42px;
  }

  .footerBtn {
    margin-top: 20px;
  }
  .journeyContainer {
    padding-bottom: 80px;
  }
}
