.insightDetails-BG {
  min-height: 40rem;
  .insightDetailsImg {
    background-repeat: no-repeat;
    width: 100%;
    position: absolute;
    height: 40rem;
    z-index: -10;
    top: 0;
  }
  .insightsLandingBGBox {
    width: 100%;
    height: 10rem;
    background: linear-gradient(180deg, #05020d 0%, #0e0320 100%);
    position: absolute;
    z-index: -10;
    top: 40rem;
  }
  .insightDetailMarkup {
    margin: 5% 0 2% 0;
  }

  .insightDetailpostTag {
    border-radius: 1.875rem;
    opacity: 0.94;
    background: #fff;
    color: #8f4d7c;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.9625rem; /* 96.25% */
    padding: 0.25rem 0.75rem 0.25rem 0.8125rem;
    margin-right: .5rem;
  }
  .insightDetailsTextCard {
    margin: 3% 0;
  }
  .insightDetailsTextCard > * {
    color: #fff;
  }
}

.insightDetailPost {
  margin: 0 25%;
  // display: flex;
}

.postShareIcons {
  > a {
    text-decoration: none;
    color: white;
    pointer-events: none;
  }
  > a:nth-child(1) {
    margin: 0.8rem 0.8rem 0.8rem 0;
  }
  > a:not(:nth-child(1)) {
    margin: 0 0.8rem;
  }
  > img {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.insightMarkdown {
  display: flex;
  color: white;
}

.markdownMainContent {
  margin: 2rem 0;
}

.markdownMainContent > .markdownFeaturedImage {
  margin-bottom: 2rem;
}

.markdownMainContent > *,
.markdownMainContent > * > img {
  width: 100%;
}

.insightDetailAuthor {
  position: absolute;
  left: 10%;
  width: 12rem;
  margin-bottom: 2rem;
  > p {
    margin-bottom: 5px;
    color: #fff;
  }
  > p:nth-child(1) {
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  > p:nth-child(2) {
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem; /* 155.556% */
    letter-spacing: -0.01125rem;
    background: var(
      --Ngenux-gradient,
      linear-gradient(97deg, #fd95ff 2.52%, #9747ff 104.52%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  > p:nth-child(3) {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 128.571% */
  }
}

.relatedInsights {
  margin: 2rem 0;
  > h3 {
    color: #e3e2ff;
    font-family: Poppins;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140.5%; /* 3.68813rem */
    letter-spacing: -0.0525rem;
  }
}
.relatedInsightsContainer {
  display: flex;
  > .insightsCatCard {
    width: 50%;
    margin-right: 4%;
    cursor: pointer;
  }
}
// -------------------------------------------mediaQurrey----------------------------------------------------------------
@media (min-width: 993px) and (max-width: 1366px) {
  .insightDetailPost {
    margin: 0 225px;
  }
  .insightDetailAuthor {
    left: 30px;
  }
}

@media (max-width: 992px) {
  .insightDetailPost {
    margin: 3rem 1rem;
  }

  .insightDetailAuthor {
    position: inherit;
  }

  .insightMarkdown {
    flex-direction: column;
  }
  .relatedInsightsContainer {
    display: block;
    > .insightsCatCard {
      width: 100%;
      margin-right: 0%;
    }
  }

  .featured-img-insight {
    height: auto;
  }
}
