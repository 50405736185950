.footerContainer {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 605px;
  background: url("../assets/footer/Footer_BG.png");
}
.yourdataOurPeopleContainer {
  width: 754px;
  height: 128px;
  position: relative;
}

.yourDataText {
  color: #fff;
  font-family: Poppins;
  font-size: 54px;
  font-style: normal;
  font-weight: 300;
  line-height: 85%; /* 45.9px */
  letter-spacing: -1.08px;
}
.businessImapctText {
  color: #fff;
  font-family: Poppins;
  font-size: 62px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 116.129% */
  letter-spacing: -1.24px;
  height: 72px;
}
.snakeArrow {
  position: absolute;
  right: 0px;
  top: 0px;
}

.snakeArrowInsights {
  right: -50px;
}

.snakeArrowInsightDetails {
  right: 10px;
  top: -2px;
}

.snakeArrowCareers {
  top: 10px;
  right: 0;
}

.snakeArrowSolutions {
  position: absolute;
  right: -36px !important;
  top: -11px !important;
}

.leftSectionFooter {
  display: flex;
  flex-direction: column;
}

.accelerateConatiner {
  width: 432px;
  color: #efedfd;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 172%; /* 30.96px */
  margin-top: 20px;
}
.footerBtnC {
  color: white;
  margin-top: 38px;
}

.tradeMark {
  color: #efedfd;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 172%; /* 30.96px */
  margin-top: 118px;
}
.footerEle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 596px;
  padding-right: 81px;
  padding-left: 81px;
  padding-bottom: 41px;
}
.rightSectionFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.yourdataOurPeopleContainer p {
  margin: 0 !important;
}

.cardSpotlight {
  gap: 1.2rem;
  padding: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  background: linear-gradient(254deg, #8e61bd 0.22%, #6149a4 111.04%);
  filter: blur(0px);
  width: 470px;
  height: 248px;
  margin-bottom: 86px;
}
.cardSpotlightSolutionsLanding {
  margin-bottom: 79px !important;
}
.spotlightText {
  color: #fff;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.subscribeText {
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
  width: 100%;
}
.cardvectorImg {
  position: absolute;
  top: 0px;
  right: 0px;
}
.linksFooter {
  gap: 40px;
  display: flex;
  height: 100%;
  align-items: flex-end;
  color: #efedfd;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 172%; /* 30.96px */
  margin-bottom: 40px;
}
.inputSection {
  position: relative;
  z-index: 1;
}

.inputSection input {
  width: 406px;
  border-radius: 30px;
  border: 1px solid #fff;
  background: #fff;
  height: 60px;
  padding: 1rem;
}
.clickBtnSportlight {
  position: absolute;
  top: 4px;
  right: 5px;
  cursor: pointer;
  background: #32174d;
  display: flex;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  padding: 0.75rem;
}
::placeholder {
  color: #808080;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.clickBtnSportlight:hover {
  img {
    transform: rotate(45deg);
    transition: 0.3s;
  }
  background: linear-gradient(263deg, #ec4d37 30.08%, #fa955d 122.52%);
}
.gradFooter {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.footerContactInfo {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 81px;
  padding-left: 81px;
  margin-bottom: 1rem;
}

.mobTrademark {
  display: none;
}
// -----------------------------------------------media-qurrey---------------------------------------------------------
@media (min-width: 993px) and (max-width: 1366px) {
  .plusIcon {
    width: 28px;
  }
  .cardSpotlight {
    max-width: 425px;
    margin-bottom: 0;
  }

  .businessImapctText {
    font-size: 42px;
  }
  .yourDataText {
    font-size: 32px;
  }

  .yourdataOurPeopleContainer {
    max-width: 500px;
    height: 90px;
  }

  .footerEle {
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 0;
    align-items: center;
  }
  .snakeArrowInsights {
    right: 3px;
    top: -14px;
  }
  .snakearrowImg {
    width: 7.5rem;
    right: -5px !important;
    top: -15px !important;
  }
  .inputSection input {
    width: 100%;
  }
  .tradeMark {
    font-size: 16px;
    margin-top: 0;
  }
  .linksFooter {
    font-size: 16px;
  }
  .footerContactInfo {
    align-items: center;
    padding: 0 30px;
  }
  .footerContainer {
    height: 490px;
  }
}

@media (max-width: 992px) {
  .yourDataText {
    color: #fff;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 85%; /* 18.7px */
    letter-spacing: -0.44px;
  }
  .plusIcon {
    width: 19px;
    height: 19px;
  }
  .businessImapctText {
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.352px; /* 118.133% */
    letter-spacing: -0.48px;
  }
  .accelerateConatiner {
    display: block;
    color: #efedfd;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 172%; /* 20.64px */
    width: auto;
  }
  .footerContactInfo {
    flex-direction: column;
    width: 100%;
    padding: 0px;
    align-items: center;
    gap: 32px;
  }
  .linksFooter {
    flex-direction: column;
    gap: 0px;
    align-items: center;
    font-size: 12px;
    margin: 0px;
    gap: 16px;
  }
  .leftSectionFooter {
    width: 100%;
    padding: 1rem;
  }
  .footerEle {
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    width: auto;
    padding: 0;
  }
  .yourdataOurPeopleContainer {
    width: 100%;
    height: auto;
    p {
      margin-bottom: 0rem;
    }
  }
  .cardSpotlight {
    width: 100%;
    padding: 0px;
  }
  .cardSpotlight input {
    width: 100%;
    height: 39px;
  }
  .arrowbuttonInput {
    height: 32px;
  }
  .footerContainer {
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .snakearrowImg {
    width: 99.73px;
    height: 50px;
    margin-top: 5px;
    right: -20px;
    position: absolute;
  }

  .snakeArrow {
    right: 55px;
  }
  .spotlightText {
    font-size: 16px;
  }
  .subscribeText {
    font-size: 14px;
    max-width: 311px;
  }
  .cardSpotlight {
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 16px;
    height: 164px;
    margin: 0px;
    gap: 14px;
    padding-bottom: 20px;
  }
  .cardvectorImgInner {
    height: 164px;
  }

  .rightSectionFooter {
    padding: 1rem !important;
  }
  .tradeMark {
    font-size: 12px;
    margin-top: 0px;
    padding-bottom: 16px;
  }
  .deskTrademark {
    display: none;
  }
  .mobTrademark {
    display: flex;
    margin-bottom: 0px;
  }
  .clickBtnSportlight {
    width: 34.298px;
    height: 34.298px;
    padding: 0.1rem;
  }
}
