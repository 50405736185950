.contactusbtnDesk {
  display: block;
  background-color: rgba(250, 86, 34, 1);
  border-radius: 40px;
  padding: 12px 24px;
  line-height: 44px;
  color: #fffefc;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  text-transform: capitalize;
  cursor: pointer;
}

.contactusbtnDesk:hover {
  background-color: white;
  color: rgba(250, 86, 34, 1);
}

@media (max-width: 1385px) {
  .contactusbtnMob {
    display: block;
    background-color: rgba(250, 86, 34, 1);
    border-radius: 40px;
    padding: 12px 24px;
    line-height: 44px;
    color: #fffefc;
    width: fit-content;
    margin-left: -10px;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 100% */
    text-transform: capitalize;
    bottom: 30%;
    cursor: pointer;
  }
}
