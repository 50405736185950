.openPositionsCard {
  display: flex;
  width: 32%;
  padding: 1.5rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4rem;
  flex-shrink: 0;
  border-radius: 0.75rem;
  background: linear-gradient(185deg, #8e61bd -26.06%, #6149a4 121.46%);
  .openPositionsCardInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.25rem;
    .openPositionLocation {
      display: flex;
      align-items: flex-start;
      > span {
        color: #fff;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 150% */
      }
    }
    > p {
      margin: 0;
      font-family: Poppins;
      font-style: normal;
      color: #fff;
    }
    .openPositionDesignation {
      font-size: 2rem;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.04rem;
    }
    > .openPositionDescription {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 2rem; /* 160% */
      height: 8rem;
    }
  }
  .openPositionsCardApply {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    > p:nth-child(1) {
      color: #fff;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem; /* 150% */
      margin: 0;
    }
    > .interactiveBtn {
      border-radius: 3.125rem;
      border: 1px solid #fff;
      background: linear-gradient(185deg, #8e61bd -26.06%, #6149a4 121.46%);
      // background-color: #6149A4;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      width: 50%;
      height: 3rem;
      > span {
        color: #fff;
        text-align: right;
        font-family: Poppins;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 132%; /* 1.65rem */
        text-transform: capitalize;
      }
    }

    > .interactiveBtn:hover {
      border-radius: 3.125rem;
      border: 1px solid #fff;
      background: #fff;
      // background-color: #6149A4;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      height: 3rem;
      > span {
        color: red;
        text-align: right;
        font-family: Poppins;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 132%; /* 1.65rem */
        text-transform: capitalize;
      }
      > img {
        content: url("../assets/redArrow.svg");
      }
    }
  }
}

// -------------------------------------------mediaQuery----------------------------------------------------------------

@media (max-width: 1385px) {
  .openPositionsContent {
    align-items: center !important;
  }
}
