.carouselContainer {
  height: 550px;
  position: relative;
  width: 100%;
  padding-left: 80px;
  z-index: 0;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: url("../assets/leftArrow.svg") !important;
  transform: rotateZ(180deg);
  position: absolute !important;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: url("../assets/leftArrow.svg") !important;
  position: absolute !important;
  top: 0px !important;
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 89%) !important;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  // left: var(--swiper-navigation-sides-offset, 47%) !important;
  // right: auto;
  left: calc(50% - 40px) !important;
  right: auto;
  transform: translateX(-50%);
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  // right: var(--swiper-navigation-sides-offset, 46%) !important;
  // left: auto;
  right: calc(50% - 40px) !important;
  left: auto;
  transform: translateX(50%);
}

.swiper-horizontal {
  position: static !important;
}

.swiper-slide {
  width: auto !important;
}
.swiper-wrapper {
  align-items: center;
  justify-content: flex-start;
}

.carouselCard {
  display: flex;
  width: 411px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 48px 32px 32px 42px;
  background: linear-gradient(105deg, #cac8fe 15.39%, #d3b8f8 94.32%);
}

.carouselTextMain {
  color: #2d2323;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.6px; /* 168% */
  width: 100%;
}

.cardFooter {
  display: flex;
  gap: 20px;
}

.footercardImg {
  width: 70px !important;
  height: 70px;
  border-radius: 100%;
}

.empName {
  color: #2d2323;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px; /* 140% */
}

.empDesig {
  color: #2d2323;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@media (min-width: 993px) and (max-width: 1366px) {
  .carouselContainer {
    padding-left: 30px;
  }
}
@media (max-width: 992px) {
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 82%) !important;
  }

  .carouselCard {
    display: flex;
    width: 343px;
    padding: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    padding: 1rem;
    margin-bottom: 12px;
  }
  .carouselTextMain {
    color: #2d2323;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
  }
  .carouselContainer {
    width: 343px;
    padding: 0px;
    height: 385px;
  }
}
